import React from "react";
import PaymentMethodList from './PaymentMethodList'
const PaymentMethodRoutes = [
{
    path: '/PaymentMethod/PaymentMethod-list',
    component: PaymentMethodList,
    permission: 'Permissions.PaymentMethods.View'
},
{
    path: '/PaymentMethod/PaymentMethod/addNew',
    component: React.lazy(() => import('./PaymentMethodForm'))
},
{
    path: '/PaymentMethod/:PaymentMethodId',
    component: React.lazy(() => import('./PaymentMethodForm')),
}
]
export default PaymentMethodRoutes