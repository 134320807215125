import { Breadcrumb, SimpleCard } from "app/components";
import React, { useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import axios from "axios.js";
import { config } from "../../../../config";
import ConfirmMessage from "../../Configuration/Message/ConfirmMessage";
import { toast } from "react-toastify";
import { useCallback } from "react";

const CustomerLedgerList = () => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [stockObj, setStockObj] = useState({});
  const [reqBody, setReqBody] = useState({});
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  const [branch, setBranches] = useState({});
  const [confirmMessage, setConfirmMessage] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const remove = (id) => {
  //   setConfirmMessage({
  //     ...confirmMessage,
  //     isOpen: false,
  //   });
  //   axios.post(config.BASE_API + "/Stock/" + id).then((res) => {
  //     fetchAPI();
  //     setNotify({
  //       isOpen: true,
  //       message: res?.data?.message,
  //       type: "error",
  //     });
  //   });
  // };

  const fetchAPI = useCallback(
    async (body) => {
      let response = await axios.post(
        config.BASE_API + "/Report/customer-ledger",
        {
          page: page,
          rowsPerPage: rowsPerPage,
          fromDate: body?.fromDate,
          toDate: body?.toDate,
          customerId: body?.customerId,
        }
      );
      response = await response.data;
      setStockObj(response?.data);
      setLoading(false);
      if (response?.succeeded === false) {
        toast.error(response.message);
      }
    },
    [page, rowsPerPage]
  );

  function print(item) {
    if (reqBody?.customerId) {
      axios({
        url: config.BASE_API + "/Report/customer-ledger/print",
        method: "post",
        data: {
          keyword: item?.keyword,
          customerId: reqBody?.customerId,
          fromDate: selectedDateFrom,
          toDate: selectedDateTo,
          isAscending: true,
        },
        responseType: "blob", // important
      }).then((response) => {
        if (response?.data?.type === "text/plain") {
          window.alert("No Result Found");
          return;
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.pdf");
        document.body.appendChild(link);
        link.click();
      });
    } else {
      window.alert("Please Select Customer");
    }
  }

  async function fetchCustomers() {
    let response = await axios.post(config.BASE_API + "/Customer/Search", {
      page: -1,
      orderBy: "name",
      isAscending: true,
    });
    response = await response.data;
    setBranches(response.data);
  }

  const handleSearch = (event) => {
    // console.log("Button Name", event?.nativeEvent?.submitter?.name)
    if (event?.nativeEvent?.submitter?.name === "search") {
      fetchAPI(reqBody);
    } else {
      print(reqBody);
    }
  };

  const handleDateChangeFrom = (date, value) => {
    setSelectedDateFrom(date);
    setReqBody({
      ...reqBody,
      fromDate: date,
    });
  };

  const handleDateChangeTo = (date, value) => {
    setSelectedDateTo(date);
    setReqBody({
      ...reqBody,
      toDate: date,
    });
  };

  const handleChange = (event) => {
    event.persist();
    setReqBody({
      ...reqBody,
      [event.target.name]: event.target.value,
    });
  };

  const { keyword, branchId, customerId } = reqBody;

  useEffect(() => {
    fetchAPI(reqBody);
    fetchCustomers();
  }, [fetchAPI]);

  return (
    <>
      {loading ? (
        "Loading"
      ) : (
        <div className="m-sm-30">
          <div className="mb-sm-30">
            <Breadcrumb
              routeSegments={[
                {
                  name: "Customer Ledger",
                  path: "/customerLedger/customerLedger-list",
                },
                { name: "Customer Ledger" },
              ]}
            />
          </div>

          <SimpleCard title="Customer Ledger">
            <ValidatorForm onSubmit={handleSearch} onError={() => null}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className="m-2"
                  margin="none"
                  label="From"
                  inputVariant="outlined"
                  type="text"
                  size="small"
                  autoOk={true}
                  format="dd/MM/yyyy"
                  value={selectedDateFrom}
                  onChange={handleDateChangeFrom}
                />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className="m-2"
                  margin="none"
                  label="To"
                  inputVariant="outlined"
                  type="text"
                  size="small"
                  autoOk={true}
                  format="dd/MM/yyyy"
                  value={selectedDateTo}
                  onChange={handleDateChangeTo}
                />
              </MuiPickersUtilsProvider>
              <TextField
                select
                className="mr-3 "
                style={{ marginTop: 10, width: 300 }}
                size="small"
                variant="outlined"
                label="Select Customer"
                name="customerId"
                validators={["required"]}
                errormessages={["This field is required"]}
                value={customerId || ""}
                onChange={handleChange}
              >
                <select value={customerId || ""}></select>
                <MenuItem value="">Select Customer</MenuItem>
                {branch?.item1?.map((item, index) => (
                  <MenuItem value={item?.id} key={index}>
                    {item?.name}
                  </MenuItem>
                ))}
              </TextField>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                name="search"
                style={{ marginTop: 10 }}
              >
                Search
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                name="print"
                style={{
                  marginTop: 10,
                  marginLeft: 10,
                }}
              >
                Download Pdf
              </Button>
              <div className="w-full overflow-auto">
                <TableContainer style={{ width: "100%", overflowX: "auto" }}>
                  <Table style={{ minWidth: 1500 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell className="px-0">Date</TableCell>
                        <TableCell className="px-0">REF NO</TableCell>
                        <TableCell className="px-0">DEBIT</TableCell>
                        <TableCell className="px-0">CREDIT</TableCell>
                        <TableCell className="px-0">BALANCE</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {stockObj?.item1.map((subscriber, index) => (
                        <TableRow key={index}>
                          <TableCell className="px-0 capitalize" align="left">
                            {subscriber.date.toString().substring(0, 10)}
                          </TableCell>
                          <TableCell className="px-0 capitalize" align="left">
                            {subscriber.refNo}
                          </TableCell>
                          <TableCell className="px-0 capitalize" align="left">
                            {subscriber.debit}
                          </TableCell>

                          <TableCell className="px-0 capitalize" align="left">
                            {subscriber.credit}
                          </TableCell>
                          <TableCell className="px-0 capitalize" align="left">
                            {subscriber.balance}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <ConfirmMessage
                  confirmMessage={confirmMessage}
                  setConfirmMessage={setConfirmMessage}
                />

                <TablePagination
                  className="px-4"
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component="div"
                  count={stockObj?.item2}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    "aria-label": "Previous Page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Next Page",
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </div>
            </ValidatorForm>
          </SimpleCard>
        </div>
      )}
    </>
  );
};

export default CustomerLedgerList;
