import React from "react";
import AccountHeadList from "./AccountHeadList";
const accountHeadRoutes = [
  {
    path: "/account-head/account-head-list",
    component: AccountHeadList,
    permission: "Permissions.AccountHeads.View",
  },
  {
    path: "/account-head/account-head/addNew",
    component: React.lazy(() => import("./AccountHeadForm")),
    permission: "Permissions.AccountHeads.Add",
  },
  {
    path: "/account-head/:accountHeadId",
    component: React.lazy(() => import("./AccountHeadForm")),
    permission: "Permissions.AccountHeads.Update",
  },
];
export default accountHeadRoutes;
