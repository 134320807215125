import React from "react";
const payReceiveRoutes = [
  {
    path: "/PayReceive/PayReceive-list",
    component: React.lazy(() => import("./PayReceiveList")),
    permission: "Permissions.PayReceives.View",
  },
  {
    path: "/PayReceive/PayReceive/addNew",
    component: React.lazy(() => import("./PayReceiveForm")),
    permission: "Permissions.PayReceives.Add",
  },
];
export default payReceiveRoutes;
