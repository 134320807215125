export const navigations = [
  {
    name: "Configuration",
    icon: "settings",
    auth: "Permissions.MasterMenus.Configuration",
    children: [
      {
        name: "Branch",
        path: "/branch/branch-list",
        icon: "business",
        iconText: "business",
        auth: "Permissions.Branches.View",
      },
      {
        name: "Supplier",
        path: "/supplier/supplier-list",
        icon: "local_shipping",
        iconText: "local_shipping",
        auth: "Permissions.Suppliers.View",
      },
      {
        name: "Category",
        path: "/configuration/categories",
        icon: "list",
        iconText: "list",
        auth: "Permissions.Categories.View",
      },
      {
        name: "Generic Name",
        path: "/generic/generic-list",
        icon: "label",
        iconText: "label",
        auth: "Permissions.GenericNames.View",
      },
      {
        name: "Measurement Unit",
        path: "/measurementUnit/measurementUnit-list",
        icon: "straighten",
        iconText: "straighten",
        auth: "Permissions.MeasurementUnits.View",
      },
      {
        name: "Country of Origin",
        path: "/configuration/countries",
        icon: "public",
        iconText: "public",
        auth: "Permissions.Countries.View",
      },
      {
        name: "Product",
        path: "/product/product-list",
        icon: "unarchive",
        iconText: "unarchive",
        auth: "Permissions.Products.View",
      },
      {
        name: "Expense Types",
        path: "/expenseType/expenseType-list",
        icon: "more",
        iconText: "more",
        auth: "Permissions.ExpenseTypes.View",
      },
      {
        name: "Customer",
        path: "/customer/customer-list",
        icon: "contacts",
        iconText: "contacts",
        auth: "Permissions.Customers.View",
      },
      {
        name: "Role",
        path: "/role/role-list",
        icon: "vpn_key",
        iconText: "vpn_key",
        auth: "Permissions.Roles.View",
      },
      {
        name: "User",
        path: "/user/user-list",
        icon: "supervisor_account",
        iconText: "supervisor_account",
        auth: "Permissions.Users.View",
      },
      {
        name: "Payment Method",
        path: "/PaymentMethod/PaymentMethod-List",
        icon: "payment",
        iconText: "payment",
        auth: "Permissions.PaymentMethods.View",
      },
    ],
  },
  {
    name: "Transaction",
    icon: "shopping_basket",
    auth: "Permissions.MasterMenus.Transaction",
    children: [
      {
        name: "Purchase",
        path: "/purchase/purchase-list",
        icon: "arrow_back",
        iconText: "arrow_back",
        auth: "Permissions.Purchases.View",
      },
      {
        name: "Supplier Payment",
        path: "/SupplierPayment/SupplierPayment-list",
        icon: "subdirectory_arrow_right",
        iconText: "subdirectory_arrow_right",
        auth: "Permissions.SupplierPayments.View",
      },
      {
        name: "Customer Due Collection",
        path: "/CustomerPayment/CustomerPayment-list",
        icon: "subdirectory_arrow_left",
        iconText: "subdirectory_arrow_left",
        auth: "Permissions.CustomerPayments.View",
      },
      {
        name: "Expense",
        path: "/expense/expense-list",
        icon: "trending_down",
        iconText: "trending_down",
        auth: "Permissions.Expenses.View",
      },
      {
        name: "Quick Transfer",
        path: "/Transfer/Transfer-list",
        icon: "compare_arrows",
        iconText: "compare_arrows",
        auth: "Permissions.Transfers.View",
      },
      {
        name: "Transfer",
        icon: "compare_arrows",
        auth: "Permissions.Transfers.View",
        children: [
          {
            name: "Transfer",
            path: "/TransferMultiple/TransferMultiple-list",
            icon: "compare_arrows",
            iconText: "compare_arrows",
            auth: "Permissions.Transfers.View",
          },
          {
            name: "Transfer Detail",
            path: "/TransferDetail/TransferDetail-list",
            icon: "compare_arrows",
            iconText: "compare_arrows",
            auth: "Permissions.Transfers.View",
          },
        ],
      },
      {
        name: "Sales",
        path: "/sale/sale-list",
        icon: "attach_money",
        iconText: "attach_money",
        auth: "Permissions.Sales.View",
      },
      {
        name: "Damage or Lost",
        path: "/damage-lost/damage-lost-list",
        icon: "delete",
        iconText: "delete",
        auth: "Permissions.DamageLosts.View",
      },
      {
        name: "Stock Adjustment",
        path: "/stock-adjustment/stock-adjustment-list",
        icon: "tune",
        iconText: "tune",
        auth: "Permissions.StockAdjustments.View",
      },
      {
        name: "Purchase Return",
        path: "/purchase-return/purchase-return-list",
        icon: "loop",
        iconText: "loop",
        auth: "Permissions.PurchaseReturns.View",
      },
    ],
  },

  {
    name: "Reports",
    icon: "insert_chart",
    auth: "Permissions.MasterMenus.Reports",
    children: [
      // {
      //     name: 'PL Report',
      //     path: '/profitLoss/profitLoss-list',
      //     iconText: 'E',
      //     auth:'Permissions.Reports.PL'
      // },
      // {
      //     name: 'Balance Report',
      //     path: '/balance/balance-list',
      //     iconText: 'E',
      //     auth:'Permissions.Reports.Balance'
      // },
      // {
      //     name: 'Customer Balance Report',
      //     path: '/CustomerBalance/CustomerBalance-list',
      //     iconText: 'E',
      // },
      // {
      //     name: 'Supplier Balance Report',
      //     path: '/SupplierBalance/SupplierBalance-list',
      //     iconText: 'E',
      // },
      //    {
      //             name: 'Supplier Ledger',
      //             path: '/supplierLedger/supplierLedger-list',
      //             iconText: 'E',
      //         },
      //{
      //             name: 'Customer Ledger',
      //             path: '/customerLedger/customerLedger-list',
      //             iconText: 'E',
      //         }

      {
        name: "Stock List",
        path: "/stock/stock-list",
        icon: "shopping_cart",
        iconText: "shopping_cart",
        auth: "Permissions.Stocks.View",
      },
      {
        name: "Out Of Stock List",
        path: "/stock/outofstock-list",
        icon: "remove_shopping_cart",
        iconText: "remove_shopping_cart",
        auth: "Permissions.Stocks.View",
      },

      {
        name: "Sale Return List",
        path: "/saleReturn/saleReturn-list",
        icon: "undo",
        iconText: "undo",
        auth: "Permissions.SaleReturns.View",
      },
      {
        name: "Item Wise Purchase List",
        path: "/ItemWisePurchase/item-wise-purchase-list",
        icon: "local_mall",
        iconText: "local_mall",
        auth: "Permissions.Purchases.ItemWisePurchase",
      },
      {
        name: "Item Wise Sale Profit Loss List",
        path: "/ItemWiseSaleProfitLoss/item-wise-sale-profit-loss-list",
        icon: "graphic_eq",
        iconText: "graphic_eq",
        auth: "Permissions.Sales.ItemWiseSaleProfitLoss",
      },
      {
        name: "Purchase History",
        path: "/PurchaseItem/purchase-item-list",
        icon: "shop_two",
        iconText: "shop_two",
        auth: "Permissions.PurchaseItems.View",
      },
      {
        name: "Sale History",
        path: "/SaleItem/sale-item-list",
        icon: "store",
        iconText: "store",
        auth: "Permissions.SaleItems.View",
      },
    ],
  },

  {
    name: "Accounts",
    icon: "account_balance",
    auth: "Permissions.MasterMenus.Accounts",
    children: [
      {
        name: "Chart of Account",
        path: "/coa/coa-list",
        icon: "assignment",
        iconText: "assignment",
        auth: "Permissions.AccountingReports.ChartOfAccounts",
      },
      {
        name: "Accounts",
        path: "/account-head/account-head-list",
        icon: "library_books",
        iconText: "library_books",
        auth: "Permissions.AccountHeads.View",
      },
      {
        name: "Payment / Receipt",
        path: "/PayReceive/PayReceive-list",
        icon: "receipt",
        iconText: "receipt",
        auth: "Permissions.PayReceives.View",
      },
      {
        name: "Journal Entries",
        path: "/journal-entry/journal-entry-list",
        icon: "import_contacts",
        iconText: "import_contacts",
        auth: "Permissions.JournalEntries.View",
      },
      {
        name: "Account Report",
        path: "/accounts/accounts-report",
        icon: "pie_chart",
        iconText: "pie_chart",
        auth: "Permissions.AccountingReports.TrialBalance_BalanceSheet_IncomeStatement",
      },
      {
        name: "Subsidiary Ledger",
        path: "/accounts/subsidiary-ledger",
        icon: "chrome_reader_mode",
        iconText: "chrome_reader_mode",
        auth: "Permissions.AccountingReports.SubsidiaryLedger",
      },
      {
        name: "Transaction Report",
        path: "/accounts/transaction-report",
        icon: "pie_chart",
        iconText: "pie_chart",
        auth: "Permissions.TransactionReports.CustomerTransaction_SupplierTransaction",
      },
    ],
  },

  // {
  //     name: 'Documentation',
  //     icon: 'launch',
  //     type: 'extLink',
  //     path: 'http://demos.ui-lib.com/matx-react-doc/',
  // },
];
