import React from "react";
import GenericList from './GenericList'
const genericRoutes = [
{
    path: '/generic/generic-list',
    component: GenericList,
    permission: 'Permissions.GenericNames.View'
},
{
    path: '/generic/generic/addNew',
    component: React.lazy(() => import('./GenericForm')),
    permission: 'Permissions.GenericNames.Add'
},
{
    path: '/generic/:genericId',
    component: React.lazy(() => import('./GenericForm')),
    permission: 'Permissions.GenericNames.Update'
}
]
export default genericRoutes