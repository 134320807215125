import React from "react";
const expenseTypeRoutes = [
  {
    path: "/expenseType/expenseType-list",
    component: React.lazy(() => import("./ExpenseTypeList")),
    permission: "Permissions.ExpenseTypes.View",
  },
  {
    path: "/expenseType/expenseType/addNew",
    component: React.lazy(() => import("./ExpenseTypeForm")),
    permission: "Permissions.ExpenseTypes.Add",
  },
  {
    path: "/expenseType/:expenseTypeId",
    component: React.lazy(() => import("./ExpenseTypeForm")),
    permission: "Permissions.ExpenseTypes.Update",
  },
];
export default expenseTypeRoutes;
