import React from "react";
import RoleList from './RoleList'
const roleRoutes = [
  {
    path: "/role/role-list",
    component: RoleList,
    permission: "Permissions.Roles.View",
  },
  {
    path: "/role/role/addNew",
    component: React.lazy(() => import("./RoleForm")),
    permission: "Permissions.Roles.Create",
  },
  {
    path: "/role/:roleId",
    component: React.lazy(() => import("./RoleForm")),
    permission: "Permissions.Roles.Edit",
  },
  {
    path: "/Auth/permissions/byrole/:roleId",
    component: React.lazy(() => import("./Permission")),
  },
];
export default roleRoutes