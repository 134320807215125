import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Box,
  Typography,
  Divider,
  TextField
} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import { Breadcrumb, MatxLoading, SimpleCard } from "app/components";
import axios from "axios.js";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { ValidatorForm } from "react-material-ui-form-validator";
import { config } from "../../../../config";
import ConfirmMessage from "../../Configuration/Message/ConfirmMessage";
import { toast } from "react-toastify";
import { Autocomplete } from "@material-ui/lab";
const TransferList = () => {
  const history = useHistory();
  const [products, setProducts] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [transferObj, setTransferObj] = useState({});
  const [reqBody, setReqBody] = useState({});
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  const [confirmMessage, setConfirmMessage] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const fetchAPI = useCallback(
    async (body) => {
      let response = await axios.post(config.BASE_API + "/Transfer/search", {
        page: page,
        rowsPerPage: rowsPerPage,
        keyword: body?.keyword,
        productId:body?.productId,
        fromDate: body?.fromDate,
        toDate: body?.toDate,
      });
      response = await response.data;
      setTransferObj(response.data);
      setLoading(false);
      if (response?.succeeded === false) {
        toast.error(response.message);
      }
    },
    [page, rowsPerPage]
  );

  useEffect(() => {
    fetchAPI(reqBody);
  }, [fetchAPI]);

  async function fetchProduct(keyword, supplierId) {
    let response = await axios.post(config.BASE_API + "/Product/search", {
      page: -1,
      keyword: keyword,
      orderBy: "name",
      isAscending: true,
      supplierIds: supplierId ? [supplierId] : [],
    });
    response = await response?.data;
    if (response?.succeeded == true) setProducts(response?.data?.item1);
  }
  const handleSearch = (event) => {
    fetchAPI(reqBody);
  };
  const handleChangeAuto = (event, newValue) => {
    setReqBody({
      ...reqBody,
      productId: newValue?.id,
    });
    event.persist();
  };

  const handleInputChangeAuto = (event, newValue) => {
    if (reqBody?.supplierId) {
      fetchProduct(newValue, reqBody?.supplierId);
      event.persist();
    } else {
      if (newValue) fetchProduct(newValue, null);
      else setProducts([]);
    }
  };
  const handleDateChangeFrom = (date, value) => {
    setSelectedDateFrom(date);
    setReqBody({
      ...reqBody,
      fromDate: date,
    });
  };

  const handleDateChangeTo = (date, value) => {
    setSelectedDateTo(date);
    setReqBody({
      ...reqBody,
      toDate: date,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const { keyword, fromDate, toDate } = reqBody;

  return (
    <>
      <div className="m-sm-30">
        <div className="mb-sm-30">
          <Breadcrumb
            routeSegments={[
              { name: "Transfer", path: "/Transfer/Transfer-list" },
              { name: "Transfer List" },
            ]}
          />
        </div>

        <ValidatorForm onSubmit={handleSearch} onError={() => null}>
          <Box marginBottom={2}>
            <SimpleCard>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                name="productId"
                options={products}
                onChange={handleChangeAuto}
                onInputChange={handleInputChangeAuto}
                getOptionLabel={(option) =>
                  option.name +
                  " " +
                  option.packSize +
                  " " +
                  option.category.name +
                  " " +
                  option.supplier.name
                }
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ marginTop: 10 }}
                    label="Select Product"
                    size="small"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className="m-2"
                  margin="none"
                  label="From"
                  inputVariant="outlined"
                  type="text"
                  size="small"
                  autoOk={true}
                  format="dd/MM/yyyy"
                  value={selectedDateFrom}
                  onChange={handleDateChangeFrom}
                />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className="m-2"
                  margin="none"
                  label="To"
                  inputVariant="outlined"
                  type="text"
                  size="small"
                  autoOk={true}
                  format="dd/MM/yyyy"
                  value={selectedDateTo}
                  onChange={handleDateChangeTo}
                />
              </MuiPickersUtilsProvider>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{ marginTop: 10 }}
              >
                Search
              </Button>
            </SimpleCard>
          </Box>
          {loading ? (
            <MatxLoading />
          ) : (
            <SimpleCard>
              <Grid container justify="space-between">
                <Grid item>
                  <Box marginBottom={2}>
                    <Typography variant="h6" component="h2">
                      Transfer List
                    </Typography>
                  </Box>
                </Grid>
                {/* <Grid item>
                  <Box marginBottom={2}>
                    <Button
                      className="mt-4"
                      color="primary"
                      variant="contained"
                      size="small"
                      onClick={() =>
                        history.push("/TransferItem/TransferItemForm")
                      }
                    >
                      + Quick Transfer
                    </Button>
                  </Box>
                </Grid> */}
              </Grid>
              <Divider style={{ background: "gray" }} variant="middle" />
              <div className="w-full overflow-auto">
                <TableContainer style={{ width: "100%", overflowX: "auto" }}>
                  <Table style={{ minWidth: 1500 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell className="px-0">Product Name</TableCell>
                        {/* <TableCell className="px-0" size="small">
                          Category
                        </TableCell>
                        <TableCell className="px-0">Pack Size</TableCell> */}
                        <TableCell className="px-0">Source</TableCell>
                        <TableCell className="px-0">Destination</TableCell>
                        <TableCell className="px-0">Quantity</TableCell>
                        <TableCell className="px-0">Amount</TableCell>
                        {/* <TableCell className="px-0">Edit</TableCell>
                        <TableCell className="px-0">Delete</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {transferObj?.item1.map((transfer, index) => (
                        <TableRow key={index}>
                          <TableCell className="px-0 " align="left">
                            {transfer?.product?.name +
                              " " +
                              transfer?.product?.packSize +
                              " " +
                              transfer?.product?.category?.name}
                          </TableCell>

                          <TableCell className="px-0 " align="left">
                            {transfer?.sourceBranch}
                          </TableCell>

                          <TableCell className="px-0 " align="left">
                            {transfer?.destinationBranch}
                          </TableCell>
                          <TableCell className="px-0 " align="left">
                            {transfer?.quantity}
                          </TableCell>
                          <TableCell className="px-0 " align="left">
                            {transfer?.amount}
                          </TableCell>

                          {/* <TableCell className="px-0">
                              <IconButton
                                onClick={() => {
                                  history.push("/Transfer/" + subscriber.id);
                                }}
                              >
                                <Icon color="primary">edit</Icon>
                              </IconButton>
                            </TableCell>
                            <TableCell className="px-0">
                              <IconButton
                                onClick={() => {
                                  setConfirmMessage({
                                    isOpen: true,
                                    title:
                                      "Are you sure to delete this record?",
                                    subTitle: "You can't undo this operation",
                                    onConfirm: () => {
                                      remove(subscriber.id);
                                    },
                                  });
                                }}
                              >
                                <Icon color="error">close</Icon>
                              </IconButton>
                            </TableCell> */}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <ConfirmMessage
                  confirmMessage={confirmMessage}
                  setConfirmMessage={setConfirmMessage}
                />

                <TablePagination
                  className="px-4"
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component="div"
                  count={transferObj?.item2 || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    "aria-label": "Previous Page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Next Page",
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </div>
            </SimpleCard>
          )}
        </ValidatorForm>
      </div>
    </>
  );
};

export default TransferList;
