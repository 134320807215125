import React from "react";
import SupplierPaymentList from './SupplierPaymentList'
const SupplierPaymentRoutes = [
{
    path: '/SupplierPayment/SupplierPayment-list',
    component: SupplierPaymentList,
    permission: 'Permissions.SupplierPayments.View'
},
{
    path: '/SupplierPayment/SupplierPayment/addNew',
    component: React.lazy(() => import('./SupplierPaymentForm')),
    permission: 'Permissions.SupplierPayments.Add'
},
{
    path: '/SupplierPayment/:supplierPaymentId',
    component: React.lazy(() => import('./SupplierPaymentForm')),
    permission: 'Permissions.SupplierPayments.Update'
}
]
export default SupplierPaymentRoutes