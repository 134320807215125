import React from "react";
const purchaseRoutes = [
{
    path: '/purchase/purchase-list',
    component: React.lazy(() => import('./PurchaseList')),
    permission: 'Permissions.Purchases.View'
},
{
    path: '/purchase/purchase/addNew',
    component: React.lazy(() => import('./PurchaseForm/PurchaseForm')),
    permission: 'Permissions.Purchases.Add'
}

]
export default purchaseRoutes