import React from "react";

const damageLostRoutes = [
  {
    path: "/damage-lost/damage-lost-add",
    component: React.lazy(() => import("./DamageLostForm")),
    permission: "Permissions.DamageLosts.Add",
  },
  {
    path: "/damage-lost/damage-lost-list",
    component: React.lazy(() => import("./DamageLostList")),
    permission: "Permissions.DamageLosts.View",
  },
];
export default damageLostRoutes;
