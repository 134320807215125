import React, { createContext, useEffect, useReducer } from "react";
import jwtDecode from "jwt-decode";
import axios from "axios.js";
import { MatxLoading } from "app/components";
import { config } from "../../config.js";

const initialState = {
  isAuthenticated: false,
  loggedOut: false,
  isInitialised: false,
  message: "",
  username: "",
  company:"",
  logo:"",
  role: "",
  permissions: [],
};

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decodedToken = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("accessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      const { isAuthenticated, message, username,company,logo, role, permissions } =
        action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        message,
        username: username,
        company:company,
        logo:logo,
        role: role,
        permissions: permissions,
      };
    }
    case "LOGIN": {
      const { message, username,company, logo,role, permissions } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        message,
        username: username,
        company:company,
        logo:logo,
        role: role,
        permissions: permissions,
        loggedOut: false,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        message: "",
        loggedOut: true,
      };
    }

    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  login: () => Promise.resolve(),
  logout: () => {},
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const login = async (username, password) => {
    const response = await axios.post(config.BASE_API + "/Auth/Login", {
      username,
      password,
    });
    const { data, message } = response.data;
    const decodedToken = jwtDecode(data);
    setSession(data);

    dispatch({
      type: "LOGIN",
      payload: {
        message,
        username: decodedToken.name,
        company:decodedToken.company,
        logo:decodedToken.logo,
        role: decodedToken.role,
        permissions: decodedToken["Permission"],
      },
    });
  };

  // const permissions = decodeToken['Permission'];
  // if (permissions === undefined || permissions.length === 0) return false;
  // return allowedData.some(a => permissions.includes(a));

  const logout = () => {
    setSession(null);
    dispatch({ type: "LOGOUT" });
  };

  useEffect(() => {
    (async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          const decodedToken = jwtDecode(accessToken);

          dispatch({
            type: "INIT",
            payload: {
              isAuthenticated: true,
              username: decodedToken.name,
              company:decodedToken.company,
              logo:decodedToken.logo,
              role: decodedToken.role,
              permissions: decodedToken["Permission"],
            },
          });
        } else {
          dispatch({
            type: "INIT",
            payload: {
              isAuthenticated: false,
              username: null,
              role: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INIT",
          payload: {
            isAuthenticated: false,
            username: null,
            role: null,
          },
        });
      }
    })();
  }, []);

  if (!state.isInitialised) {
    return <MatxLoading />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
