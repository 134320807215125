import NotFound from './NotFound'
import ForgotPassword from './ForgotPassword'
import JwtLogin from './login/JwtLogin'
import Unauthorized from './Unauthorized'

const sessionRoutes = [
  
    {
        path: '/session/signin',
        component: JwtLogin,
    },
    {
        path: '/session/forgot-password',
        component: ForgotPassword,
    },
    {
        path: '/session/404',
        component: NotFound,
    },
     {
        path: '/session/401',
        component: Unauthorized,
    },
]

export default sessionRoutes
