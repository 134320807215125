import { Card, CircularProgress, MenuItem, TextField } from "@material-ui/core";
import { Breadcrumb } from "app/components";
import React, { useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios.js";
import { useHistory } from "react-router-dom";
import { config } from "../../../../config";
import { Button, Grid } from "@material-ui/core";
import { toast } from "react-toastify";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));
const TransferForm = () => {
  const history = useHistory();
  const classes = useStyles();
  const [branches, setBranches] = useState([]);
  const [products, setProducts] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [reqBody, setReqBody] = useState({});
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("Quick Transfer");
  const [stockItem, setStockItem] = useState(0);

  async function fetchSupplier() {
    let response = await axios.post(config.BASE_API + "/Supplier/search", {
      page: -1,
      orderBy: "name",
      isAscending: true,
    });
    response = await response?.data;
    setSuppliers(response?.data?.item1);
  }

  async function fetchProduct(supplierIds) {
    let response = await axios.post(config.BASE_API + "/Product/search", {
      page: -1,
      supplierIds,
    });
    response = await response?.data;
    if (response?.data?.item1) {
      setProducts(response?.data?.item1);
    } else {
      alert("No Product Found");
    }
  }

  async function fetchBranches() {
    let response = await axios.post(config.BASE_API + "/Branch/Search", {
      page: -1,
    });
    response = await response?.data;
    setBranches(response?.data?.item1);
  }

  useEffect(() => {
    fetchSupplier();
    fetchBranches();
  }, []);

  function RedirectToList() {
    history.push("/Transfer/Transfer-list");
  }

  // add/update promise
  const saveItem = (body) =>
    new Promise((resolve, reject) => {
      if (transferQty <= stockItem) {
        console.log("Inside saveItem", body);
        if (!body.id) {
          axios
            .post(config.BASE_API + "/Transfer", body)
            .then((res) => {
              if (res?.data?.succeeded === true) {
                toast.success(res?.data?.message);
                RedirectToList();
                setLoading(false);
                setReqBody({});
              } else {
                toast.error(res?.data?.message);
                setLoading(false);
              }
            })
            .catch((err) => {
              toast.error("Something Went Wrong");
              console.log(err);
            });
        }
        resolve();
        setReqBody({});
      } else {
        window.alert("Transfer Limit Exceeded");
        setLoading(false);
        return;
      }
    });

  const handleSubmit = (event) => {
    saveItem(reqBody)
      .then((x) => {})
      .catch((error) => {
        console.log(error);
      });
    setLoading(false);
  };

  const handleChangeSupplier = (event, value) => {
    if (reqBody?.sourceId) {
      fetchProduct([value?.id]);
      event.persist();
    } else {
      alert("Please Select Source Branch first");
    }
  };

  const handleChangeAuto = (event, newValue) => {
    if (reqBody?.sourceId) {
      setReqBody({
        ...reqBody,
        productId: newValue?.id,
      });
      axios
        .get(
          config.BASE_API + "/Stock/" + reqBody?.sourceId + "/" + newValue?.id
        )
        .then((res) => {
          if (res?.data?.inStock) setStockItem(res?.data?.inStock);
          else {
            alert("Out of Stock");
            setStockItem(0);
          }
        })
        .catch((err) => console.log(err));
      event.persist();
    } else {
      alert("Please Select Source Branch first");
    }
  };

  const handleChange = (event) => {
    if (event?.target?.value < 0) return;

    setReqBody({
      ...reqBody,
      [event.target.name]: event.target.value,
    });

    if (event.target.name === "destinationId") {
      if (sourceId) {
        if (sourceId === event.target.value) {
          alert("You already select this branch as Source Branch");
          setReqBody({
            ...reqBody,
            [event.target.name]: null,
          });
        }
      } else {
        alert("Please Select Source Branch First");
        setReqBody({
          ...reqBody,
          [event.target.name]: null,
        });
      }
    }

    event.persist();
  };

  const { sourceId, destinationId, productId, transferQty, product } = reqBody;

  return (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb
          routeSegments={[
            {
              name: "Transfer List",
              path: "/Transfer/Transfer-list",
            },
            { name: title },
          ]}
        />
      </div>

      <Card className="px-6 pt-2 pb-4">
        {/* Form Start Here */}
        <ValidatorForm onSubmit={handleSubmit} onError={() => null}>
          <Grid container spacing={6}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                select
                className="mb-4 w-full"
                label="Select Source"
                name="sourceId"
                required
                value={sourceId || ""}
                helperText="source branch is also treated as supplier"
                onChange={handleChange}
              >
                <select value={sourceId || ""}></select>
                {branches?.map((branch, index) => (
                  <MenuItem value={branch?.id} key={index}>
                    {branch?.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                select
                className="mb-4 w-full"
                label="Select Destination"
                name="destinationId"
                required
                value={destinationId || ""}
                onChange={handleChange}
              >
                <select value={destinationId || ""}></select>
                <MenuItem value="">Select Destination Branch</MenuItem>
                {branches?.map((branch, index) => (
                  <MenuItem value={branch?.id} key={index}>
                    {branch?.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                name="supplierId"
                options={suppliers}
                onChange={handleChangeSupplier}
                getOptionLabel={(option) => option.name}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Supplier"
                    required
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                name="productId"
                options={products}
                onChange={handleChangeAuto}
                getOptionLabel={(option) =>
                  option.name +
                  " " +
                  option.packSize +
                  " " +
                  option.category.name
                }
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Product"
                    required
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                className="mb-4 w-full"
                label="Transfer Quantity"
                onChange={handleChange}
                type="number"
                name="transferQty"
                value={transferQty || ""}
                required
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                className="mb-4 w-full"
                label="Item Quantity"
                type="text"
                name="inStock"
                value={stockItem || "0"}
                disabled
              />
            </Grid>
          </Grid>
          <div className="flex flex-wrap items-center mb-4">
            <div className="relative">
              <Button
                variant="contained"
                color="primary"
                disabled={loading}
                type="submit"
              >
                Transfer
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </div>
        </ValidatorForm>
        {/* Form End Here */}
      </Card>
    </div>
  );
};

export default TransferForm;
