import React from "react";
import ProductList from "./ProductList"

const productRoutes = [
{
    path: '/product/product-list',
    component: ProductList,
    permission: 'Permissions.Products.View'
},
{
    path: '/product/product/addNew',
    component: React.lazy(() => import('./ProductForm')),
    permission: 'Permissions.Products.Add'
},
{
    path: '/product/:productId',
    component: React.lazy(() => import('./ProductForm')),
    permission: 'Permissions.Products.Update'
}
]
export default productRoutes