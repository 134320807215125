import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  Icon,
  MenuItem,
  TablePagination,
  TextField,
  Box,
} from "@material-ui/core";
import Moment from 'moment';
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Breadcrumb, SimpleCard } from "app/components";
import axios from "axios.js";
import React, { useCallback, useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { toast } from "react-toastify";
import { config } from "../../../../config";
import ConfirmMessage from "../../Configuration/Message/ConfirmMessage";

function Row(props) {
  const { saleReturn } = props;
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <TableRow hover={true}>
        <TableCell width="10%" size="small" colSpan={1}>
          <IconButton aria-label="expand row" onClick={() => setOpen(!open)}>
            {open ? (
              <Icon color="error">arrow_drop_up</Icon>
            ) : (
              <Icon color="error">arrow_drop_down</Icon>
            )}
          </IconButton>
        </TableCell>
        <TableCell width="10%">{saleReturn.saleNo}</TableCell>
        <TableCell width="10%">
          {saleReturn.saleReturnDate.toString().substring(0, 10)}
        </TableCell>
        <TableCell width="10%">{saleReturn.customerName}</TableCell>
        <TableCell width="15%">{saleReturn.branchName}</TableCell>
        <TableCell width="10%">{saleReturn.totalItem}</TableCell>
        <TableCell width="10%">{saleReturn.discount}</TableCell>
        <TableCell width="10%">{saleReturn.subtotal}</TableCell>
        <TableCell width="10%">{Moment(saleReturn.createdOn).format('llll')}</TableCell>
      </TableRow>
      <TableRow hover={true}>
        <TableCell
          size="small"
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={5}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <SimpleCard title="item">
              <Table size="small" aria-label="saleReturns">
                <TableHead>
                  <TableRow>
                    <TableCell>Product</TableCell>
                    <TableCell>SaleReturn Price</TableCell>
                    <TableCell>Return Quantity</TableCell>
                    <TableCell>Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {saleReturn.saleReturnItems.map((saleReturnItem) => (
                    <TableRow key={saleReturnItem.id}>
                      <TableCell>{saleReturnItem.productName}</TableCell>
                      <TableCell>{saleReturnItem.price}</TableCell>
                      <TableCell>{saleReturnItem.quantity}</TableCell>
                      <TableCell>{saleReturnItem.amount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </SimpleCard>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const SaleReturnList = () => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [reqBody, setReqBody] = useState({});
  const [branches, setBranches] = useState({});
  const [saleReturnObj, setSaleReturnObj] = useState({});
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  const [confirmMessage, setConfirmMessage] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const handleDateChangeFrom = (date, value) => {
    setSelectedDateFrom(date);
    setReqBody({
      ...reqBody,
      fromDate: date,
    });
  };

  const handleDateChangeTo = (date, value) => {
    setSelectedDateTo(date);
    setReqBody({
      ...reqBody,
      toDate: date,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fetchAPI = useCallback(
    async (body) => {
      let response = await axios.post(config.BASE_API + "/SaleReturn/Search", {
        page: page,
        rowsPerPage: rowsPerPage,
        keyword: body?.keyword,
        fromDate: body?.fromDate,
        toDate: body?.toDate,
        branchId: body?.branchId,
      });
      response = await response.data;
      setSaleReturnObj(response.data);
      setLoading(false);
      if (response?.succeeded === false) {
        toast.error(response.message);
      }
    },
    [page, rowsPerPage]
  );

  async function fetchBranches() {
    let response = await axios.post(config.BASE_API + "/Branch/Search", {
      page: -1,
    });
    response = await response.data;
    setBranches(response.data);
  }

  function print(body) {
    if (body?.branchId) {
      axios({
        url: config.BASE_API + "/SaleReturn/print",
        method: "post",
        data: {
          keyword: body?.keyword,
          branchId: body?.branchId,
          fromDate: body?.fromDate,
          toDate: body?.toDate,
          page: -1,
        },
        responseType: "blob", // important
      }).then((response) => {
        if (response?.data?.type === "text/plain") {
          window.alert("No Result Found");
          return;
        }
      //Create a Blob from the PDF Stream
      const file = new Blob([response.data], { type: "application/pdf" });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      const pdfWindow = window.open();
      pdfWindow.location.href = fileURL;
      });
    } else {
      window.alert("Please Select Branch");
    }
  }

  useEffect(() => {
    fetchAPI(reqBody);
    fetchBranches();
  }, [fetchAPI]);

  const handleSearch = (event) => {
    if (event?.nativeEvent?.submitter?.name === "search") {
      fetchAPI(reqBody);
    } else if (event?.nativeEvent?.submitter?.name === "print") {
      print(reqBody);
    }
  };

  const handleChange = (event) => {
    event.persist();
    setReqBody({
      ...reqBody,
      [event.target.name]: event.target.value,
    });
  };

  const { keyword, fromDate, toDate, branchId } = reqBody;

  return (
    <>
      <div className="m-sm-30">
        <div className="mb-sm-30">
          <Breadcrumb
            routeSegments={[
              {
                name: "Sale Return",
                path: "/saleReturn/saleReturn-list",
              },
              { name: "sale Return List" },
            ]}
          />
        </div>
        <ValidatorForm onSubmit={handleSearch} onError={() => null}>
          <Box mb={2}>
            <SimpleCard>
              <TextField
                className="m-2"
                label="Search By Keywords"
                name="keyword"
                size="small"
                variant="outlined"
                value={keyword || ""}
                onChange={handleChange}
              />
              <TextField
                select
                className="m-2 "
                style={{ width: 300 }}
                size="small"
                variant="outlined"
                label="Select Branch"
                name="branchId"
                validators={["required"]}
                errormessages={["This field is required"]}
                value={branchId || ""}
                onChange={handleChange}
              >
                <select value={branchId || ""}></select>
                {branches?.item1?.map((branch, index) => (
                  <MenuItem value={branch?.id} key={index}>
                    {branch?.name}
                  </MenuItem>
                ))}
              </TextField>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className="m-2"
                  margin="none"
                  label="From"
                  inputVariant="outlined"
                  type="text"
                  size="small"
                  autoOk={true}
                  format="dd/MM/yyyy"
                  value={selectedDateFrom}
                  onChange={handleDateChangeFrom}
                />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className="m-2"
                  margin="none"
                  label="To"
                  inputVariant="outlined"
                  type="text"
                  size="small"
                  autoOk={true}
                  format="dd/MM/yyyy"
                  value={selectedDateTo}
                  onChange={handleDateChangeTo}
                />
              </MuiPickersUtilsProvider>
              <Button
                className="m-2"
                variant="contained"
                color="primary"
                type="submit"
                name="search"
              >
                Search
              </Button>
              <Button
                className="m-2"
                variant="contained"
                color="primary"
                type="submit"
                name="print"
                style={{ marginTop: 10, marginLeft: 10 }}
              >
                Download Pdf
              </Button>
            </SimpleCard>
          </Box>
          {loading ? (
            "Loading"
          ) : (
            <SimpleCard title="Sale Return List">
              <Divider style={{ background: "gray" }} variant="middle" />
              <div className="w-full">
                <TableContainer style={{ width: "100%", overflowX: "auto" }}>
                  <Table style={{ minWidth: 1500 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Items</TableCell>
                        <TableCell>Reference No</TableCell>
                        <TableCell>Return Date</TableCell>
                        <TableCell>Customer</TableCell>
                        <TableCell>Branch</TableCell>
                        <TableCell>Total Product</TableCell>
                        <TableCell>Discount</TableCell>
                        <TableCell>Subtotal</TableCell>
                        <TableCell>Created On</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {saleReturnObj?.item1.map((saleReturn, index) => (
                        <Row key={index} saleReturn={saleReturn} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <ConfirmMessage
                confirmMessage={confirmMessage}
                setConfirmMessage={setConfirmMessage}
              />
              <TablePagination
                className="px-4"
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={saleReturnObj?.item2 || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  "aria-label": "Previous Page",
                }}
                nextIconButtonProps={{
                  "aria-label": "Next Page",
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </SimpleCard>
          )}
        </ValidatorForm>
      </div>
    </>
  );
};
export default SaleReturnList;
