import React, { useState } from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Field, FieldArray } from "formik";
import { config } from "../../../../config";
import axios from "axios.js";

import { TextField as TF } from "formik-material-ui";

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Button,
  IconButton,
  Icon,
  TableContainer,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

const TransferDetailsTable = ({
  values,
  errors,
  touched,
  handleChange,
  setFieldValue,
}) => {
  const [itemId, setItemId] = useState("");
  return (
    <FieldArray name="transferDetails">
      {(arrayHelpers) => (
        <div className="overflow-auto">
          <TableContainer style={{ width: "100%", overflowX: "auto" }}>
            <Table style={{ minWidth: 1500 }}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={4}>Item </TableCell>
                  <TableCell colSpan={3}>Available Qty</TableCell>
                  <TableCell colSpan={3}>Transfer Qty </TableCell>
                  <TableCell colSpan={2} className="p-0" align="center">
                    Remove
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.transferDetails?.map((item, index) => (
                  <TableRow className="position-relative" key={index}>
                    <TableCell colSpan={4} className="p-0" align="left">
                      <div className="flex purchaseItems-center">
                        <Autocomplete
                          className="w-full"
                          size="small"
                          options={values.productList}
                          getOptionLabel={(option) => option.productName}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              fullWidth
                            />
                          )}
                          inputValue={item?.productName || ""}
                          onInputChange={(event, newValue) => {
                            setFieldValue(
                              `transferDetails[${index}].productName`,
                              newValue
                            );
                          }}
                          onChange={(event, newValue) => {
                            var items = values.transferDetails.filter(
                              (x) => x.productId === newValue?.id
                            );

                            if (items.length > 0) {
                              alert(
                                "You Already Added This Item. Please Select Another One"
                              );
                              arrayHelpers.remove(index);
                            }
                            else {
                                
                                handleChange({
                                  target: {
                                    name: `transferDetails[${index}].productId`,
                                    value: newValue?.id,
                                  },
                                });
                              }
                            axios
                              .get(
                                config.BASE_API +
                                  "/Stock/" +
                                  values.sourceId +
                                  "/" +
                                  newValue?.id
                              )
                              .then((res) => {
                                if (res?.data?.inStock){
                                    setFieldValue(
                                        `transferDetails[${index}].availableQty`,
                                        res?.data?.inStock
                                      );
                                }

                                else {
                                  alert("Out of Stock");
                                }
                              })
                              .catch((err) => console.log(err));
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell colSpan={3} className="p-0" align="left">
                      <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        type="text"
                        name={`transferDetails[${index}].availableQty`}
                        value={item?.availableQty || ""}
                        disabled
                      />
                    </TableCell>
                   
                    <TableCell colSpan={3} className="p-0" align="left">
                      <Field
                        name={`transferDetails[${index}].transferQty`}
                        size="small"
                        variant="outlined"
                        type="number"
                        fullWidth
                        component={TF}
                        value={item?.transferQty || ""}
                        onChange={(e) => {
                          e.preventDefault();
                          const { value } = e.target;

                          const regex =
                            /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                          if (regex.test(value.toString())) {
                            //Start---transferQty will be less than or equal to avaiableQty
                            const availableQty = parseFloat(
                              item?.availableQty || 0
                            );
                            const transferQty = parseFloat(value);
                            if (transferQty > availableQty) {
                              setFieldValue(
                                `transferDetails[${index}].transferQty`,
                                availableQty
                              );
                            } else {
                              setFieldValue(
                                `transferDetails[${index}].transferQty`,
                                value
                              );
                            }
                            //end---transferQty will be less than or equal to avaiableQty

                            //Previous code start
                            // setFieldValue(
                            //   `transferDetails[${index}].transferQty`,
                            //   value
                            // );
                            //Previous code end
                          } else {
                            setFieldValue(
                              `transferDetails[${index}].transferQty`,
                              0
                            );
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell colSpan={2} className="p-0" align="center">
                      <IconButton
                        size="small"
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        <Icon color="error" fontSize="small">
                          clear
                        </Icon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <div>
            {typeof errors.transferDetails === "string" ? (
              <p style={{ color: "red" }}> {errors.transferDetails}</p>
            ) : null}
          </div>

          <Button
            className="mt-4"
            color="primary"
            variant="contained"
            size="small"
            onClick={() => {
              arrayHelpers.push({});
            }}
          >
            + Add New Item
          </Button>
        </div>
      )}
    </FieldArray>
  );
};
export default TransferDetailsTable;
