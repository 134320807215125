import React from "react";
import { Redirect } from "react-router-dom";

import dashboardRoutes from "./views/Dashboard/DashboardRoutes";
import utilitiesRoutes from "./views/utilities/UtilitiesRoutes";

import chartsRoute from "./views/components/charts/ChartsRoute";
import dragAndDropRoute from "./views/components/Drag&Drop/DragAndDropRoute";
import materialRoutes from "./views/components/material-kit/MaterialRoutes";

import accountHeadRoutes from "./views/Accounts/AccountHead/accountHeadRoutes";
import COARoutes from "./views/Accounts/coaRoutes";
import payReceiveRoutes from "./views/Accounts/PayReceive/payReceiveRoutes";
import formsRoutes from "./views/components/forms/FormsRoutes";
import branchRoutes from "./views/Configuration/Branch/brunchRoutes";
import configurationRoutes from "./views/Configuration/configurationRoutes";
import CustomerRoutes from "./views/Configuration/Customer/customerRoutes";
import genericRoutes from "./views/Configuration/Generic/genericRoutes";
import measurementRoutes from "./views/Configuration/MeasurementUnit/measurementRoute";
import PaymentMethodRoutes from "./views/Configuration/PaymentMethod/paymentMethodRoutes";
import productRoutes from "./views/Configuration/Product/productRoutes";
import roleRoutes from "./views/Configuration/Role/roleRoutes";
import supplierRoutes from "./views/Configuration/Supplier/supplierRoutes";
import userRoutes from "./views/Configuration/User/userRoute";
import balanceRoutes from "./views/LegacyReports/BalanceReport/balanceRoute";
import CustomerLedgerRoutes from "./views/LegacyReports/CustomerLedger/customerLedgerRoutes";
import ProfitLossRoutes from "./views/LegacyReports/ProfitLoss/ProfitLossRoutes";
import reportRoutes from "./views/LegacyReports/reportRoutes";
import SupplierLedgerRoutes from "./views/LegacyReports/SupplierLedger/supplierLedgerRoutes";
import purchaseReturnRoutes from "./views/Transaction/PurchaseReturn/purchaseReturnRoutes";
import SaleReturnRoutes from "./views/Report/SaleReturn/saleReturnRoutes";
import StockRoutes from "./views/Report/Stock/stockRoutes";
import ItemWisePurchaseRoutes from "./views/Report/ItemWisePurchase/ItemWisePurchaseRoutes";
import saleItemRoutes from "./views/Report/SaleItem/SaleItemRoutes";
import purchaseItemRoutes from "./views/Report/PurchaseItem/PurchaseItemRoutes";
import ItemWiseSaleProfitLossRoutes from "./views/Report/ItemWiseSaleProfitLoss/ItemWiseSaleProfitLossRoutes";
import CustomerPaymentRoutes from "./views/Transaction/CustomerPayment/customerPaymentRoutes";
import damageLostRoutes from "./views/Transaction/DamageLost/damageLostRoutes.js";
import expenseRoutes from "./views/Transaction/Expense/Expense/expenseRoutes";
import expenseTypeRoutes from "./views/Configuration/ExpenseType/expenseTypeRoutes";
import purchaseRoutes from "./views/Transaction/Purchase/purchaseRoutes";
import SaleRoutes from "./views/Transaction/Sale/saleRoutes";
import stockAdjustmentRoutes from "./views/Transaction/StockAdjustment/stockAdjustmentRoutes";
import SupplierPaymentRoutes from "./views/Transaction/SupplierPayment/supplierPaymentRoutes";
import transferRoutes from "./views/Transaction/Transfer/transferRoutes";
import transferMultipleRoutes from "./views/Transaction/TransferMultiple/TransferMultipleRoutes";
import transferDetailRoutes from "./views/Transaction/TransferDetail/TransferDetailRoutes";


const redirectRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard/default" />,
  },
];

const errorRoute = [
  {
    component: () => <Redirect to="/session/404" />,
  },
];

const routes = [
  ...dashboardRoutes,
  ...materialRoutes,
  ...utilitiesRoutes,
  ...chartsRoute,
  ...dragAndDropRoute,
  ...measurementRoutes,
  ...genericRoutes,
  ...expenseTypeRoutes,
  ...expenseRoutes,
  ...PaymentMethodRoutes,
  ...SupplierPaymentRoutes,
  ...CustomerPaymentRoutes,
  ...payReceiveRoutes,
  ...SupplierLedgerRoutes,
  ...CustomerLedgerRoutes,
  ...roleRoutes,
  ...reportRoutes,
  ...branchRoutes,
  ...CustomerRoutes,
  ...balanceRoutes,
  ...COARoutes,
  ...accountHeadRoutes,
  ...ProfitLossRoutes,
  ...purchaseRoutes,
  ...purchaseReturnRoutes,
  ...userRoutes,
  ...supplierRoutes,
  ...SaleRoutes,
  ...StockRoutes,
  ...formsRoutes,
  ...configurationRoutes,
  ...transferRoutes,
  ...transferMultipleRoutes,
  ...transferDetailRoutes,
  ...damageLostRoutes,
  ...stockAdjustmentRoutes,
  ...supplierRoutes,
  ...SaleReturnRoutes,
  ...ItemWisePurchaseRoutes,
  ...saleItemRoutes,
  ...purchaseItemRoutes,
  ...ItemWiseSaleProfitLossRoutes,
  ...productRoutes,
  ...redirectRoute,
  ...errorRoute,
];

export default routes;
