import BalanceList from "./BalanceList";

const balanceRoutes = [
  {
    path: "/balance/balance-list",
    component: BalanceList,
    permission: "Permissions.Reports.Balance",
  },
];
export default balanceRoutes;
