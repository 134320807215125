import OutOfStockList from "./OutOfStockList";
import StockList from "./StockList";

const StockRoutes = [
  {
    path: "/stock/stock-list",
    component: StockList,
    permission: "Permissions.Stocks.View",
  },
  {
    path: "/stock/outofstock-list",
    component: OutOfStockList,
  },
];
export default StockRoutes;
