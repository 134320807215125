import React from 'react'
import CategoryList from './Category/CategoryList'
import CountryList from './Country/CountryList'


const configurationRoutes = [
    {
        path: '/configuration/categories',
        component: CategoryList,
        permission: 'Permissions.Categories.View'
    },
    {
        path: '/configuration/country/:countryid',
        component: React.lazy(() => import('./Country/CountryForm')),
        permission: 'Permissions.Countries.Update'
    },
   
    {
        path: '/configuration/countries',
        component: CountryList,
        permission: 'Permissions.Countries.View'
    },
    {
        path: '/configuration/country',
        component: React.lazy(() => import('./Country/CountryForm')),
        permission: 'Permissions.Countries.Add'
    },
    {
        path: '/configuration/category',
        component: React.lazy(() => import('./Category/CategoryForm')),
        permission: 'Permissions.Categories.Add'
    },
    {
        path: '/configuration/:categoryId',
        component: React.lazy(() => import('./Category/CategoryForm')),
        permission: 'Permissions.Categories.Update'
    }
   
   
]

export default configurationRoutes
