import {
  Card,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import axios from "axios.js";
import * as React from "react";
import { useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { config } from "../../../../config";

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
    height: "1000px",
    width: "2500px",
  },
  dialogTitle: {
    textAlign: "center",
  },
  dialogContent: {
    textAlign: "center",
  },
  dialogAction: {
    justifyContent: "center",
  },
  titleIcon: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      cursor: "default",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "8rem",
    },
  },
}));

export default function SaleReturn({ values }) {
  const history = useHistory();
  const [open, setOpen] = useState(true);
  const [supplierName, setSupplierName] = useState("");
  const [branchName, setBranchName] = useState("");
  // const [purchaseDate, setPurchaseDate] = useState("");
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState(values);
  const [tempState, setTempState] = useState();
  let TotalReturn = 0;

  fetchBranchToShow(values?.branchId);
  fetchSupplierToShow(values?.supplierId);
  const handleChangeReason = (event, ind) => {
    setState({ ...state, Reason: event?.target?.value });
    // setState(state => {
    //     const saleItems = [...state?.saleItems];
    //     saleItems[ind] = { ...saleItems[ind], 'returnQty': event?.target?.value };
    //     return { ...state, saleItems };
    // });
    event.persist();
    // setTempState(state)
    // changeQuantity(event?.target?.value)
  };

  const handleChange = (event, ind) => {
    if (
      event?.target?.value < 0 ||
      event?.target?.value > state?.saleItems[ind]?.quantity
    )
      return;

    setState((state) => {
      const saleItems = [...state?.saleItems];
      saleItems[ind] = { ...saleItems[ind], returnQty: event?.target?.value };
      return { ...state, saleItems };
    });
    event.persist();
    setTempState(state);
    // changeQuantity(event?.target?.value)
  };

  function RedirectToList() {
    history.push("/saleReturn/saleReturn-list");
    window.location.reload();
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const styles = (theme) => ({
  //   paper: {
  //     height: 300,
  //     width: "100%",
  //     marginTop: theme.spacing.unit * 3,
  //     overflow: "auto",
  //   },
  // });

  // const calculateSubTotal = (itemList = []) => {
  //     let subTotal = 0
  //     itemList.forEach((item) => {
  //         subTotal += calculateAmount(item)
  //     })

  //     return subTotal
  // }

  // const calculateTotal = (state) => {
  //   let total = 0;
  //   total += state?.subtotal;
  //   total += state?.additionalCost || 0;
  //   total -= state?.discount || 0;
  //   total = total - TotalReturn;
  //   return total;
  // };

  const calculateReturnQty = (item) => {
    let ReturnQty = 0;
    if (item?.returnQty) {
      ReturnQty = item?.returnQty;
    }
    return ReturnQty;
  };

  const calculateReturnAmount = (item) => {
    let returnAmount = 0;

    if (item?.returnQty > 0) {
      returnAmount += 1;
      returnAmount = item?.returnQty * item.price;
    }
    TotalReturn += returnAmount;
    return returnAmount;
  };

  // const calculateReturnForAmount = (item) => {
  //   let returnAmount = 0;
  //   if (item?.returnQty > 0) {
  //     returnAmount += 1;
  //     returnAmount = item?.returnQty * item.price;
  //   }
  //   return returnAmount;
  // };

  const calculateTotalReturnAmount = () => {
    return TotalReturn;
  };

  const calculateTotalDiscount = () => {
    return (TotalReturn * state?.discountInPercentage) / 100;
  };

  async function saveItem(item) {
    let res = await axios.post(
      config.BASE_API + "/SaleReturn/returnItem",
      item
    );
    setOpen(false);
    if (res?.data?.succeeded === true) {
      toast.success(res?.data?.message);
      RedirectToList();
      setLoading(false);
    } else {
      toast.info(res?.data?.message);
      setLoading(false);
    }
  }

  const handleSubmit = (event) => {
    const totalReturnQuantity = state?.saleItems.reduce(
      (accumulator, object) => {
        return accumulator + object.returnQty;
      },
      0
    );
    if (totalReturnQuantity > 0) {
      saveItem(state);
    } else {
      alert("Return Quantity can not be 0 !!!");
    }
  };

  async function fetchBranchToShow(branchId) {
    if (typeof branchId !== "undefined") {
      let response = await axios.get(config.BASE_API + "/branch/" + branchId);
      let data = response.data.data;
      setBranchName(data?.name);
    }
  }

  async function fetchSupplierToShow(supplierId) {
    if (typeof supplierId !== "undefined") {
      let response = await axios.get(
        config.BASE_API + "/supplier/" + supplierId
      );
      let data = response.data.data;
      setSupplierName(data?.name);
    }
  }

  const { reason } = state;

  return (
    <>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
          name="Return"
          size="small"
          style={{ marginTop: 10, marginLeft: 10 }}
        >
          Return
        </Button>
        <Dialog
          fullWidth
          maxWidth="xl"
          scroll={"body"}
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>Sale Return</DialogTitle>
          <Card elevation={3}>
            <div className="flex p-4">
              <h4 className="m-0">Sale Return</h4>
            </div>
            <Divider className="mb-2" />

            <ValidatorForm className="p-4" onSubmit={handleSubmit}>
              <Grid container spacing={3} alignItems="center">
                {/* <Grid item md={2} sm={4} xs={12}>
                                            Vendor Name
                                        </Grid>
                                        <Grid item md={4} sm={8} xs={12}>
                                            <h5>{supplierName}</h5>

                                        </Grid> */}

                <Grid item md={2} sm={4} xs={12}>
                  Branch
                </Grid>
                <Grid item md={4} sm={8} xs={12}>
                  <h5>{state?.branchName}</h5>
                </Grid>

                <Grid item md={2} sm={4} xs={12}>
                  Sale No
                </Grid>
                <Grid item md={4} sm={8} xs={12}>
                  <div className="flex flex-wrap m--2">
                    <h5>{state?.saleNo}</h5>
                  </div>
                </Grid>

                <Grid item md={2} sm={4} xs={12}>
                  Sale Date
                </Grid>
                <Grid item md={4} sm={8} xs={12}>
                  <div className="flex flex-wrap m--2">
                    <h5>{state?.saleDate}</h5>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>

              <div className="mb-8">
                <TableContainer style={{ maxHeight: 1000 }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Item</TableCell>

                        {/* <TableCell colSpan={2}>Batch </TableCell> */}
                        <TableCell>Sale Quantity </TableCell>

                        <TableCell colSpan={1}>Sale Price</TableCell>
                        <TableCell>Return Quantity</TableCell>
                        <TableCell>Return Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {state?.saleItems?.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{item.productName}</TableCell>
                          <TableCell>
                            {item?.quantity - calculateReturnQty(item)}
                          </TableCell>
                          {/* <TableCell colSpan={2}>{item.batchNo} </TableCell> */}

                          <TableCell>{item.price} </TableCell>
                          <TableCell colSpan={1} align="center">
                            <div className="flex flex-wrap m--2">
                              <TextField
                                className="m-2 min-w-188"
                                //label="Return Quantity"
                                name="returnQty"
                                size="small"
                                type="number"
                                variant="outlined"
                                value={item?.returnQty || ""}
                                onChange={(event) => handleChange(event, index)}
                              />
                            </div>
                          </TableCell>
                          <TableCell>
                            {calculateReturnAmount(item).toFixed(2)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>

              <div className="mb-8">
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      label="Return Cause"
                      name="reason"
                      size="small"
                      variant="outlined"
                      type="text"
                      multiline
                      rows={6}
                      // validators={['required']}
                      //   errormessages={['This field is required']}
                      value={reason}
                      fullWidth
                      required
                      onChange={handleChangeReason}
                    ></TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <Card className="bg-default p-4" elevation={0}>
                      <Grid
                        container
                        spacing={3}
                        justify="space-between"
                        alignItems="center"
                      >
                        <Grid item xs={6}>
                          Total
                        </Grid>
                        <Grid item xs={6}>
                          <div className="text-right">
                            {calculateTotalReturnAmount().toFixed(2)}
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div className="flex items-center">
                            <span className="whitespace-pre">
                              (-)DiscountOnSale ({state?.discountInPercentage}%)
                            </span>
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div className="text-right">
                            {calculateTotalDiscount().toFixed(2)}
                          </div>
                        </Grid>

                        <Grid item xs={6}>
                          <h5 className="m-0">Total Return Amount</h5>
                        </Grid>
                        <Grid item xs={6}>
                          <div className="text-right">
                            <h5 className="m-0">
                              {(
                                calculateTotalReturnAmount() -
                                calculateTotalDiscount()
                              ).toFixed(2)}
                            </h5>
                          </div>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </div>

              <div className="mt-6">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ marginTop: 10, marginLeft: 10 }}
                >
                  Submit
                </Button>
              </div>
            </ValidatorForm>

            {/* </Formik> */}
          </Card>
          <DialogActions>
            <Button color="primary" onClick={handleClose}>
              Back To Sale
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
