import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";
import axios from "axios.js";
import React, { useEffect, useState } from "react";
import { Breadcrumb, MatxLoading } from "app/components";
import { Formik } from "formik";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router-dom";
import { array, number, object, string } from "yup";
import { config } from "../../../../config";
import TransferDetailsTable from "./TransferDetailsTable";
import { toast } from "react-toastify";

const initialValues = {
  productList: [
    {
      productName: "Select Product",
    },
  ],
  transferDetails: [{}],
};

const TransfermultipleForm = () => {
  const history = useHistory();
  const [title, setTitle] = useState("Add New Transfer");
  const [reqBody, setReqBody] = useState({});
  const [loading, setLoading] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [branches, setBranches] = useState([]);

  async function fetchSuppliers() {
    let response = await axios.post(config.BASE_API + "/Supplier/Search", {
      page: -1,
    });
    response = await response.data;
    setSuppliers(response?.data?.item1);
  }

  async function fetchBranches() {
    let response = await axios.post(config.BASE_API + "/Branch/Search", {
      page: -1,
    });
    response = await response.data;
    setBranches(response?.data?.item1);
  }
  function RedirectToList() {
    history.push("/TransferMultiple/TransferMultiple-list");
  }
  const saveItem = (item, resetForm) =>
    new Promise((resolve, reject) => {
      console.log("TransferDetailsItem===>", item);
      axios
        .post(config.BASE_API + "/TransferMultiple", item)
        .then((res) => {
          if (res?.data?.succeeded === true) {
            toast.success(res?.data?.message);
            RedirectToList();
            setLoading(false);
            setReqBody({});
          } else {
            toast.error(res?.data?.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          toast.error("Something Went Wrong");
          console.log(err);
        });
      resolve();
    });

  const handleSubmit = async (values, { isSubmitting, resetForm }) => {
    isSubmitting=true;
    resetForm({});
    setLoading(true);
    saveItem(values, resetForm)
      .then((x) => {
        isSubmitting=false;
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //autocomplete start
  const [options, setOptions] = useState([]);
  const loadingAutoComplete = true && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loadingAutoComplete) {
      return undefined;
    }

    (async () => {
      let response = await axios.post(config.BASE_API + "/Supplier/Search", {
        page: -1,
      });
      response = await response.data;

      if (active) {
        setOptions(
          response?.data?.item1.map((option) => {
            const firstLetter = option.name[0].toUpperCase();
            return {
              firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
              ...option,
            };
          })
        );
      }
    })();

    return () => {
      active = false;
    };
  }, [loadingAutoComplete]);

  useEffect(() => {
    fetchSuppliers();
    fetchBranches();
  }, []);

  return loading ? (
    <MatxLoading />
  ) : (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb
          routeSegments={[
            {
              name: "Transfer List",
              path: "/TransferMultiple/TransferMultiple-List",
            },
            { name: title },
          ]}
        />
      </div>
      <Card elevation={3}>
        <Formik
          initialValues={initialValues}
          validationSchema={object({
            supplierId: string().required("Please Select Vendor"),
            sourceId: string().required("Please Select Source Branch"),
            destinationId: string().required(
              "Please Select Destination Branch"
            ),
            transferDetails: array(
              object({
                transferQty: number()
                  .required("Required")
                  .min(1, "Quantity needs to be at least 1"),
              })
            ).min(1, "You need to provide at least 1 transfer item"),
          })}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <form className="p-4" onSubmit={handleSubmit}>
              <Grid container spacing={3} alignItems="center">
                <Grid item md={2} sm={4} xs={12}>
                  Source Branch:
                </Grid>
                <Grid item md={4} sm={8} xs={12}>
                  <TextField
                    required
                    select
                    className="min-w-200"
                    label="Select Branch"
                    name="sourceId"
                    value={values.sourceId || ""}
                    helperText={touched.sourceId ? errors.sourceId : ""}
                    error={errors.sourceId && Boolean(errors.sourceId)}
                    onChange={(e) => {
                      e.preventDefault();
                      const { value } = e.target;
                      handleChange({
                        target: {
                          name: `sourceId`,
                          value: value,
                        },
                      });
                      if (values.destinationId) {
                        if (values.destinationId === value) {
                          alert(
                            "You already select this branch as Destination Branch"
                          );
                          handleChange({
                            target: {
                              name: `sourceId`,
                              value: null,
                            },
                          });
                        }
                      }
                    }}
                    size="small"
                    variant="outlined"
                  >
                    <select value={values.sourceId || ""}></select>
                    <MenuItem value="">Select Source Branch</MenuItem>
                    {branches?.map((branch, index) => (
                      <MenuItem value={branch?.id} key={index}>
                        {branch?.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item md={2} sm={4} xs={12}>
                  Destination Branch:
                </Grid>
                <Grid item md={4} sm={8} xs={12}>
                  <TextField
                    required
                    select
                    className="min-w-200"
                    label="Select Branch"
                    name="destinationId"
                    value={values.destinationId || ""}
                    helperText={
                      touched.destinationId ? errors.destinationId : ""
                    }
                    error={
                      errors.destinationId && Boolean(errors.destinationId)
                    }
                    onChange={(e) => {
                      e.preventDefault();
                      const { value } = e.target;
                      handleChange({
                        target: {
                          name: `destinationId`,
                          value: value,
                        },
                      });
                      if (values.sourceId) {
                        if (values.sourceId === value) {
                          alert(
                            "You already select this branch as Source Branch"
                          );
                          handleChange({
                            target: {
                              name: `destinationId`,
                              value: null,
                            },
                          });
                        }
                      } else {
                        alert("Please Select Source Branch First");
                        handleChange({
                          target: {
                            name: `destinationId`,
                            value: null,
                          },
                        });
                      }
                    }}
                    size="small"
                    variant="outlined"
                  >
                    <select value={values.destinationId || ""}></select>
                    <MenuItem value="">Select Destination Branch</MenuItem>
                    {branches?.map((branch, index) => (
                      <MenuItem value={branch?.id} key={index}>
                        {branch?.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={2} sm={4} xs={12}>
                  Supplier Name:
                </Grid>
                <Grid item md={4} sm={8} xs={12}>
                  <Autocomplete
                    onChange={async (e, v) => {
                      setFieldValue("productList", []);
                      setFieldValue("supplierId", v?.id);
                      let response = await axios.get(
                        config.BASE_API + "/Autocomplete/" + v?.id
                      );
                      response = await response.data;
                      setFieldValue("productList", response?.data);
                    }}
                    id="asynchronous-demo"
                    className="min-w-188"
                    getOptionLabel={(option) => option.name}
                    options={options.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    loading={loadingAutoComplete}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Supplier"
                        variant="outlined"
                        size="small"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loadingAutoComplete ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
              <div className="mb-8">
                <TransferDetailsTable
                  values={values}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                />
              </div>
              <div className="mt-6">
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                  size="small"
                  color="primary"
                  startIcon={
                    isSubmitting ? (
                      <CircularProgress size="0.9rem" />
                    ) : undefined
                  }
                >
                  {isSubmitting ? "Submitting" : "Submit"}
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </Card>
    </div>
  );
};
export default TransfermultipleForm;
