import { Breadcrumb, SimpleCard } from "app/components";
import React, { useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";
import axios from "axios.js";
import { config } from "../../../config";
import { toast } from "react-toastify";
import { useCallback } from "react";

const CustomerBalanceReports = () => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [reqBody, setReqBody] = useState({});
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [customerBalanceObj, setCustomerBalanceObj] = useState({});

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function print(item) {
    axios({
      url: config.BASE_API + "/Customer/print",
      method: "post",
      data: { keyword: item?.keyword },
      responseType: "blob", // important
    }).then((response) => {
      if (response?.data?.type === "text/plain") {
        window.alert("No Result Found");
        return;
      }
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.pdf");
      document.body.appendChild(link);
      link.click();
    });
  }

  const handleSearch = (event) => {
    // console.log("Button Name", event?.nativeEvent?.submitter?.name)
    if (event?.nativeEvent?.submitter?.name === "search") {
      fetchAPI(reqBody);
    } else if (event?.nativeEvent?.submitter?.name === "print") {
      print(reqBody);
    }
  };

  const handleChange = (event) => {
    event.persist();
    setReqBody({
      ...reqBody,
      [event.target.name]: event.target.value,
    });
  };

  const fetchAPI = useCallback(
    async (body) => {
      let response = await axios.post(config.BASE_API + "/Customer/Search", {
        page: page,
        rowsPerPage: rowsPerPage,
        keyword: body?.keyword,
      });
      response = await response.data;
      setCustomerBalanceObj(response.data);
      setLoading(false);
      if (response?.succeeded === false) {
        toast.error(response.message);
      }
    },
    [page, rowsPerPage]
  );

  const { keyword } = reqBody;

  useEffect(() => {
    fetchAPI(reqBody);
  }, [fetchAPI]);

  return (
    <>
      {loading ? (
        "Loading"
      ) : (
        <div className="m-sm-30">
          <div className="mb-sm-30">
            <Breadcrumb
              routeSegments={[
                {
                  name: "CustomerBalance",
                  path: "/CustomerBalance/CustomerBalance-list",
                },
                { name: "CustomerBalance List" },
              ]}
            />
          </div>

          <SimpleCard title="CustomerBalance List">
            <ValidatorForm onSubmit={handleSearch} onError={() => null}>
              <TextField
                className="mr-3"
                label="Search By Keywords"
                name="keyword"
                size="small"
                variant="outlined"
                value={keyword || ""}
                onChange={handleChange}
              />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                name="search"
              >
                Search
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                name="print"
                style={{
                  marginTop: 10,
                  marginLeft: 10,
                }}
              >
                Download Pdf
              </Button>
              <div className="w-full overflow-auto">
                <TableContainer style={{ width: "100%", overflowX: "auto" }}>
                  <Table style={{ minWidth: 1500 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell className="px-0">Code</TableCell>
                        <TableCell className="px-0">Name</TableCell>
                        <TableCell className="px-0">Balance</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {customerBalanceObj?.item1.map((subscriber, index) => (
                        <TableRow key={index}>
                          <TableCell className="px-0 capitalize" align="left">
                            {subscriber.code}
                          </TableCell>
                          <TableCell className="px-0 capitalize" align="left">
                            {subscriber.name}
                          </TableCell>
                          <TableCell className="px-0 capitalize" align="left">
                            {subscriber.balance}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <TablePagination
                  className="px-4"
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component="div"
                  count={customerBalanceObj?.item2}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    "aria-label": "Previous Page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Next Page",
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </div>
            </ValidatorForm>
          </SimpleCard>
        </div>
      )}
    </>
  );
};

export default CustomerBalanceReports;
