import {
  Button,
  MenuItem,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Grid,
  Box,
  Typography,
  Divider,
} from "@material-ui/core";
import { Breadcrumb, SimpleCard } from "app/components";
import axios from "axios.js";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { ValidatorForm } from "react-material-ui-form-validator";
import { config } from "../../../../config";
import ConfirmMessage from "../../Configuration/Message/ConfirmMessage";
import { toast } from "react-toastify";

function downloadVoucher(SupplierPaymentId) {
  axios({
    url: config.BASE_API + "/supplierPayment/voucher/" + SupplierPaymentId,
    method: "get",
    responseType: "blob", // important
  }).then((response) => {
    if (response?.data?.type === "text/plain") {
      window.alert("No Result Found");
      return;
    }
    //Create a Blob from the PDF Stream
    const file = new Blob([response.data], { type: "application/pdf" });
    //Build a URL from the file
    const fileURL = URL.createObjectURL(file);
    //Open the URL on new Window
    const pdfWindow = window.open();
    pdfWindow.location.href = fileURL;
  });
}

const SupplierPaymentList = () => {
  const history = useHistory();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [reqBody, setReqBody] = useState({});
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  const [branches, setBranches] = useState({});
  const [supplierPaymentObj, setSupplierPaymentObj] = useState({});
  const [confirmMessage, setConfirmMessage] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const fetchAPI = useCallback(
    async (body) => {
      let response = await axios.post(
        config.BASE_API + "/SupplierPayment/search",
        {
          page: page,
          rowsPerPage: rowsPerPage,
          keyword: body?.keyword,
          branchId: body?.branchId,
          fromDate: body?.fromDate,
          toDate: body?.toDate,
        }
      );
      response = await response.data;
      setSupplierPaymentObj(response.data);
      setLoading(false);
      if (response?.succeeded === false) {
        toast.error(response.message);
      }
    },
    [page, rowsPerPage]
  );

  async function fetchBranches() {
    let response = await axios.post(config.BASE_API + "/Branch/Search", {
      page: -1,
    });
    response = await response.data;
    setBranches(response.data);
  }

  useEffect(() => {
    fetchAPI();
    fetchBranches();
  }, [fetchAPI]);

  const handleSearch = (event) => {
    if (event?.nativeEvent?.submitter?.name === "search") {
      fetchAPI(reqBody);
    } else {
      print(reqBody);
    }
  };

  const handleDateChangeFrom = (date, value) => {
    setSelectedDateFrom(date);
    setReqBody({
      ...reqBody,
      fromDate: date,
    });
  };

  const handleDateChangeTo = (date, value) => {
    setSelectedDateTo(date);
    setReqBody({
      ...reqBody,
      toDate: date,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const expiry = (expDate) => {
    if (expDate == null) return null;
    return expDate.toString().substring(0, 10);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const remove = (id) => {
  //   setConfirmMessage({
  //     ...confirmMessage,
  //     isOpen: false,
  //   });
  //   axios.delete(config.BASE_API + "/SupplierPayment/" + id).then((res) => {
  //     if (res?.data?.succeeded === true) {
  //       toast.error(res?.data?.message);
  //       fetchAPI();
  //     } else {
  //       toast.error(res?.data?.message);
  //     }
  //   });
  // };

  const handleChange = (event) => {
    event.persist();
    setReqBody({
      ...reqBody,
      [event.target.name]: event.target.value,
    });
  };

  function print(item) {
    axios({
      url: config.BASE_API + "/SupplierPayment/print",
      method: "post",
      data: {
        keyword: item?.keyword,
        branchId: reqBody?.branchId,
        fromDate: fromDate,
        toDate: toDate,
      },
      responseType: "blob", // important
    }).then((response) => {
      if (response?.data?.type === "text/plain") {
        window.alert("No Result Found");
        return;
      }
      //Create a Blob from the PDF Stream
      const file = new Blob([response.data], { type: "application/pdf" });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      const pdfWindow = window.open();
      pdfWindow.location.href = fileURL;
    });
  }

  const { keyword, branchId, fromDate, toDate } = reqBody;

  return (
    <>
      <div className="m-sm-30">
        <div className="mb-sm-30">
          <Breadcrumb
            routeSegments={[
              {
                name: "Supplier Payment",
                path: "/SupplierPayment/SupplierPayment-list",
              },
              { name: "Supplier Payment List" },
            ]}
          />
        </div>
        <ValidatorForm onSubmit={handleSearch} onError={() => null}>
          <Box marginBottom={2}>
            <SimpleCard>
              <TextField
                className="m-2"
                label="Search By Keywords"
                name="keyword"
                size="small"
                variant="outlined"
                value={keyword || ""}
                onChange={handleChange}
              />
              {/* <Button variant="contained" color="primary" type="submit">
                Search
              </Button> */}
              <TextField
                select
                className="m-2"
                style={{ width: 300 }}
                size="small"
                variant="outlined"
                label="Select Branch"
                name="branchId"
                validators={["required"]}
                errormessages={["This field is required"]}
                value={branchId || ""}
                onChange={handleChange}
              >
                <select value={branchId || ""}></select>
                <MenuItem value="">Select Branch</MenuItem>
                {branches?.item1?.map((branch, index) => (
                  <MenuItem value={branch?.id} key={index}>
                    {branch?.name}
                  </MenuItem>
                ))}
              </TextField>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className="m-2"
                  margin="none"
                  label="From"
                  inputVariant="outlined"
                  type="text"
                  size="small"
                  autoOk={true}
                  format="dd/MM/yyyy"
                  value={selectedDateFrom}
                  onChange={handleDateChangeFrom}
                />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className="m-2"
                  margin="none"
                  label="To"
                  inputVariant="outlined"
                  type="text"
                  size="small"
                  autoOk={true}
                  format="dd/MM/yyyy"
                  value={selectedDateTo}
                  onChange={handleDateChangeTo}
                />
              </MuiPickersUtilsProvider>
              <Button
                className="m-2"
                variant="contained"
                color="primary"
                type="submit"
                name="search"
              >
                Search
              </Button>
              <Button
                className="m-2"
                variant="contained"
                color="primary"
                type="submit"
                name="print"
              >
                Download Pdf
              </Button>
            </SimpleCard>
          </Box>
          {loading ? (
            "Loading"
          ) : (
            <SimpleCard>
              <Grid container justify="space-between">
                <Grid item>
                  <Box marginBottom={2}>
                    <Typography variant="h6" component="h2">
                      Supplier Payment List
                    </Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Box marginBottom={2}>
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      onClick={() =>
                        history.push("/SupplierPayment/SupplierPayment/addNew")
                      }
                    >
                      + Add New Supplier Payment
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              <Divider style={{ background: "gray" }} variant="middle" />
              <div className="w-full overflow-auto">
                <TableContainer style={{ width: "100%", overflowX: "auto" }}>
                  <Table style={{ minWidth: 1500 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell width="10%" className="px-0">
                          BillNo
                        </TableCell>
                        <TableCell width="10%" className="px-0">
                          Branch
                        </TableCell>
                        <TableCell width="15%" className="px-0" size="small">
                          Supplier Name
                        </TableCell>
                        <TableCell width="10%" className="px-0">
                          Payment Method
                        </TableCell>
                        <TableCell width="10%" className="px-0">
                          Payment Date
                        </TableCell>

                        <TableCell width="10%" className="px-0">
                          Payment Amount
                        </TableCell>
                        <TableCell width="15%" className="px-0">
                          Remarks
                        </TableCell>
                        <TableCell width="10%" className="px-0">
                          Created On
                        </TableCell>
                        <TableCell width="10%" className="px-0">
                          Voucher
                        </TableCell>
                        {/* <TableCell className="px-0">Edit</TableCell>
                        <TableCell className="px-0">Delete</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {supplierPaymentObj?.item1.map((payment, index) => (
                        <TableRow key={index}>
                          <TableCell width="10%" className="px-0 " align="left">
                            {payment.billNo}
                          </TableCell>
                          <TableCell width="10%" className="px-0 " align="left">
                            {payment.branchName}
                          </TableCell>
                          <TableCell
                            width="15%"
                            className="px-0 "
                            align="left"
                            size="small"
                          >
                            {payment.supplier.name}
                          </TableCell>
                          <TableCell width="10%" className="px-0 " align="left">
                            {payment.paymentMethod.name}
                          </TableCell>

                          <TableCell width="10%" className="px-0 " align="left">
                            {expiry(payment.paymentDate)}
                          </TableCell>

                          <TableCell width="10%" className="px-0 " align="left">
                            {payment.paymentAmount}
                          </TableCell>
                          <TableCell width="15%" className="px-0 " align="left">
                            {payment.remark}
                          </TableCell>
                          <TableCell width="10%" className="px-0 " align="left">
                            {payment.createdOn.substring(0, 10)}
                          </TableCell>
                          <TableCell width="10%" className="px-0 " align="left">
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={(e) => downloadVoucher(payment.id, e)}
                              name="Download"
                              size="small"
                              style={{ marginTop: 10, marginLeft: 10 }}
                            >
                              <Icon>archive</Icon>
                            </Button>
                          </TableCell>

                          {/* <TableCell className="px-0">
                            <IconButton
                              onClick={() => {
                                history.push(
                                  "/SupplierPayment/" + subscriber.id
                                );
                              }}
                            >
                              <Icon color="primary">edit</Icon>
                            </IconButton>
                          </TableCell>
                          <TableCell className="px-0">
                            <IconButton
                              onClick={() => {
                                setConfirmMessage({
                                  isOpen: true,
                                  title: "Are you sure to delete this record?",
                                  subTitle: "You can't undo this operation",
                                  onConfirm: () => {
                                    remove(subscriber.id);
                                  },
                                });
                              }}
                            >
                              <Icon color="error">close</Icon>
                            </IconButton>
                          </TableCell> */}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <ConfirmMessage
                  confirmMessage={confirmMessage}
                  setConfirmMessage={setConfirmMessage}
                />

                <TablePagination
                  className="px-4"
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component="div"
                  count={supplierPaymentObj?.item2 || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    "aria-label": "Previous Page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Next Page",
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </div>
            </SimpleCard>
          )}
        </ValidatorForm>
      </div>
    </>
  );
};

export default SupplierPaymentList;
