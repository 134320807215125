import React from "react";
import SupplierList from './SupplierList'
const supplierRoutes = [
{
    path: '/supplier/supplier-list',
    component: SupplierList,
    permission: 'Permissions.Suppliers.View'
},
{
    path: '/supplier/supplier/addNew',
    component: React.lazy(() => import('./SupplierForm'))
},
{
    path: '/supplier/:supplierId',
    component: React.lazy(() => import('./SupplierForm')),
}
]
export default supplierRoutes