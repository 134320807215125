import React from "react";

const COARoutes = [
  {
    path: "/coa/coa-list",
    component: React.lazy(() => import("./coa")),
    permission: "Permissions.AccountingReports.ChartOfAccounts",
  },
  {
    path: "/journal-entry/addNew",
    component: React.lazy(() => import("./JournalEntry/JournalEntryForm")),
    permission: "Permissions.JournalEntries.Add",
  },
  {
    path: "/journal-entry/journal-entry-list",
    component: React.lazy(() => import("./JournalEntryList/JournalEntries")),
    permission: "Permissions.JournalEntries.View",
  },
  {
    path: "/accounts/accounts-report",
    component: React.lazy(() => import("./AccountsReport")),
    permission:
      "Permissions.AccountingReports.TrialBalance_BalanceSheet_IncomeStatement",
  },
  {
    path: "/accounts/transaction-report",
    component: React.lazy(() => import("./TransactionReport")),
    permission:
      "Permissions.TransactionReports.CustomerTransaction_SupplierTransaction",
  },
  {
    path: "/accounts/subsidiary-ledger",
    component: React.lazy(() => import("./SubsidiaryLedger")),
    permission: "Permissions.AccountingReports.SubsidiaryLedger",
  },
];
export default COARoutes;
