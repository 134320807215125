import { Breadcrumb, SimpleCard } from "app/components";
import React, { useCallback, useEffect, useState } from "react";

import { ValidatorForm } from "react-material-ui-form-validator";

import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import axios from "axios.js";
import { config } from "../../../../config";
import ConfirmMessage from "../../Configuration/Message/ConfirmMessage";
import { toast } from "react-toastify";

const BalanceList = () => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [reqBody, setReqBody] = useState({});
  const [stockObj, setStockObj] = useState({});
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  const [branches, setBranches] = useState({});
  const [confirmMessage, setConfirmMessage] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const fetchAPI = useCallback(
    async (body) => {
      let response = await axios.post(config.BASE_API + "/Report/balance", {
        page: page,
        rowsPerPage: rowsPerPage,
        fromDate: body?.fromDate,
        toDate: body?.toDate,
        branchId: body?.branchId,
      });
      response = await response.data;
      setStockObj(response?.data);
      setLoading(false);
      if (response?.succeeded === false) {
        toast.error(response.message);
      }
    },
    [page, rowsPerPage]
  );

  function print(body) {
    axios({
      url: config.BASE_API + "/Report/balance/print",
      method: "post",
      data: {
        keyword: body?.keyword,
        branchId: body?.branchId,
        fromDate: body?.fromDate,
        toDate: body?.toDate,
      },
      responseType: "blob", // important
    }).then((response) => {
      if (response?.data?.type === "text/plain") {
        window.alert("No Result Found");
        return;
      }
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.pdf");
      document.body.appendChild(link);
      link.click();
    });
  }

  async function fetchBranches() {
    let response = await axios.post(config.BASE_API + "/Branch/Search", {
      page: -1,
    });
    response = await response.data;
    setBranches(response.data);
  }

  useEffect(() => {
    fetchAPI(reqBody);
    fetchBranches();
  }, [fetchAPI]);

  const handleSearch = (event) => {
    // console.log("Button Name", event?.nativeEvent?.submitter?.name)
    if (event?.nativeEvent?.submitter?.name === "search") {
      fetchAPI(reqBody);
    } else {
      print(reqBody);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDateChangeFrom = (date, value) => {
    setSelectedDateFrom(date);
    setReqBody({
      ...reqBody,
      fromDate: date,
    });
  };

  const handleDateChangeTo = (date, value) => {
    setSelectedDateTo(date);
    setReqBody({
      ...reqBody,
      toDate: date,
    });
  };

  const handleChange = (event) => {
    event.persist();
    setReqBody({
      ...reqBody,
      [event.target.name]: event.target.value,
    });
  };

  const { keyword, branchId, supplierId, fromDate, toDate } = reqBody;

  return (
    <>
      {loading ? (
        "Loading"
      ) : (
        <div className="m-sm-30">
          <div className="mb-sm-30">
            <Breadcrumb
              routeSegments={[
                { name: "Balance", path: "/balance/balance-list" },
                { name: "Balance" },
              ]}
            />
          </div>

          <SimpleCard title="Balance">
            <ValidatorForm onSubmit={handleSearch} onError={() => null}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className="m-2"
                  margin="none"
                  label="From"
                  inputVariant="outlined"
                  type="text"
                  size="small"
                  autoOk={true}
                  format="dd/MM/yyyy"
                  value={selectedDateFrom}
                  onChange={handleDateChangeFrom}
                />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className="m-2"
                  margin="none"
                  label="To"
                  inputVariant="outlined"
                  type="text"
                  size="small"
                  autoOk={true}
                  format="dd/MM/yyyy"
                  value={selectedDateTo}
                  onChange={handleDateChangeTo}
                />
              </MuiPickersUtilsProvider>
              <TextField
                select
                className="mr-3 "
                style={{ marginTop: 10, width: 300 }}
                size="small"
                variant="outlined"
                label="Select Branch"
                name="branchId"
                validators={["required"]}
                errormessages={["This field is required"]}
                value={branchId || ""}
                onChange={handleChange}
              >
                <select value={branchId || ""}></select>
                <MenuItem value="">Select Branch</MenuItem>
                {branches?.item1?.map((branch, index) => (
                  <MenuItem value={branch?.id} key={index}>
                    {branch?.name}
                  </MenuItem>
                ))}
              </TextField>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                name="search"
                style={{ marginTop: 10 }}
              >
                Search
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                name="print"
                style={{
                  marginTop: 10,
                  marginLeft: 10,
                }}
              >
                Download Pdf
              </Button>
              <div className="w-full overflow-auto">
                <TableContainer style={{ width: "100%", overflowX: "auto" }}>
                  <Table style={{ minWidth: 1500 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell className="px-0">Date</TableCell>
                        <TableCell className="px-0">Cash Sales</TableCell>
                        <TableCell className="px-0">
                          Customer Due Collection
                        </TableCell>
                        <TableCell className="px-0">Expense</TableCell>
                        <TableCell className="px-0">Sales Return</TableCell>
                        <TableCell className="px-0">Supplier Payment</TableCell>
                        <TableCell className="px-0">Balance</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {stockObj?.item1.map((subscriber, index) => (
                        <TableRow key={index}>
                          <TableCell className="px-0 capitalize" align="left">
                            {subscriber.date.toString().substring(0, 10)}
                          </TableCell>
                          <TableCell className="px-0 capitalize" align="left">
                            {subscriber.cashSales}
                          </TableCell>
                          <TableCell className="px-0 capitalize" align="left">
                            {subscriber.customerDueCollection}
                          </TableCell>

                          <TableCell className="px-0 capitalize" align="left">
                            {subscriber.expens}
                          </TableCell>
                          <TableCell className="px-0 capitalize" align="left">
                            {subscriber.salesReturn}
                          </TableCell>
                          <TableCell className="px-0 capitalize" align="left">
                            {subscriber.supplierPayment}
                          </TableCell>
                          <TableCell className="px-0 capitalize" align="left">
                            {subscriber.balance}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <ConfirmMessage
                  confirmMessage={confirmMessage}
                  setConfirmMessage={setConfirmMessage}
                />

                <TablePagination
                  className="px-4"
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component="div"
                  count={stockObj?.item2}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    "aria-label": "Previous Page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Next Page",
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </div>
            </ValidatorForm>
          </SimpleCard>
        </div>
      )}
    </>
  );
};

export default BalanceList;
