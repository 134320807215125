import React from "react";
import CustomerPaymentList from './CustomerPaymentList'
const CustomerPaymentRoutes = [
{
    path: '/CustomerPayment/CustomerPayment-list',
    component: CustomerPaymentList,
    permission: 'Permissions.CustomerPayments.View'
},
{
    path: '/CustomerPayment/CustomerPayment/addNew',
    component: React.lazy(() => import('./CustomerPaymentForm')),
    permission: 'Permissions.CustomerPayments.Add'
},
{
    path: '/CustomerPayment/:customerPaymentId',
    component: React.lazy(() => import('./CustomerPaymentForm')),
    permission: 'Permissions.CustomerPayments.Update'
}
]
export default CustomerPaymentRoutes