import {
  Button,
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Grid,
  Box,
  Typography,
  Divider,
} from "@material-ui/core";
import { Breadcrumb, SimpleCard } from "app/components";
import axios from "axios.js";
import React, { useCallback, useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { config } from "../../../../config";
import ConfirmMessage from "../Message/ConfirmMessage";

const CountryList = () => {
  const history = useHistory();
  const [reqBody, setReqBody] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [countryObj, setCountryObj] = useState({});
  const [confirmMessage, setConfirmMessage] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const fetchAPI = useCallback(
    async (body) => {
      let response = await axios.post(config.BASE_API + "/Country/Search", {
        page: page,
        rowsPerPage: rowsPerPage,
        keyword: body?.keyword,
      });
      response = await response.data;
      setCountryObj(response.data);
      setLoading(false);
      if (response?.succeeded === false) {
        toast.error(response.message);
      }
    },
    [page, rowsPerPage]
  );

  useEffect(() => {
    fetchAPI(reqBody);
  }, [fetchAPI]);

  const handleSearch = (event) => {
    fetchAPI(reqBody);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChange = (event) => {
    event.persist();
    setReqBody({
      ...reqBody,
      [event.target.name]: event.target.value,
    });
  };

  const remove = (id) => {
    setConfirmMessage({
      ...confirmMessage,
      isOpen: false,
    });
    axios.post(config.BASE_API + "/Country/" + id).then((res) => {
      if (res?.data?.succeeded === true) {
        toast.error(res?.data?.message);
        fetchAPI();
      } else {
        toast.info(res?.data?.message);
      }
    });
  };

  const { keyword } = reqBody;

  return (
    <>
      <div className="m-sm-30">
        <div className="mb-sm-30">
          <Breadcrumb
            routeSegments={[
              { name: "Country", path: "/configuration/countries" },
              { name: "Country List" },
            ]}
          />
        </div>
        <ValidatorForm onSubmit={handleSearch} onError={() => null}>
          <Box marginBottom={2}>
            <SimpleCard>
              <TextField
                className="mr-3"
                label="Search By Keywords"
                name="keyword"
                size="small"
                variant="outlined"
                value={keyword || ""}
                onChange={handleChange}
              />
              <Button variant="contained" color="primary" type="submit">
                Search
              </Button>
            </SimpleCard>
          </Box>
          {loading ? (
            "Loading"
          ) : (
            <SimpleCard>
              <Grid container justify="space-between">
                <Grid item>
                  <Box marginBottom={2}>
                    <Typography variant="h6" component="h2">
                      Country List
                    </Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Box marginBottom={2}>
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      onClick={() => history.push("/configuration/country")}
                    >
                      + Add New Country
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              <Divider style={{ background: "gray" }} variant="middle" />
              <div className="w-full overflow-auto">
                <TableContainer style={{ width: "100%", overflowX: "auto" }}>
                  <Table style={{ minWidth: 1500 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell width="90%" className="px-0">
                          Name
                        </TableCell>
                        <TableCell width="5%" className="px-0">
                          Edit
                        </TableCell>
                        <TableCell width="5%" className="px-0">
                          Delete
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {countryObj?.item1.map((subscriber, index) => (
                        <TableRow key={index}>
                          <TableCell className="px-0 capitalize" align="left">
                            {subscriber.name}
                          </TableCell>
                          <TableCell width="5%" className="px-0">
                            <IconButton
                              onClick={() => {
                                history.push(
                                  "/configuration/country/" + subscriber.id
                                );
                              }}
                            >
                              <Icon color="primary">edit</Icon>
                            </IconButton>
                          </TableCell>
                          <TableCell width="5%" className="px-0">
                            <IconButton
                              onClick={() => {
                                setConfirmMessage({
                                  isOpen: true,
                                  title: "Are you sure to delete this record?",
                                  subTitle: "You can't undo this operation",
                                  onConfirm: () => {
                                    remove(subscriber.id);
                                  },
                                });
                              }}
                            >
                              <Icon color="error">close</Icon>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <ConfirmMessage
                  confirmMessage={confirmMessage}
                  setConfirmMessage={setConfirmMessage}
                />
                <TablePagination
                  className="px-4"
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component="div"
                  count={countryObj?.item2}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    "aria-label": "Previous Page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Next Page",
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </div>
            </SimpleCard>
          )}
        </ValidatorForm>
      </div>
    </>
  );
};

export default CountryList;
