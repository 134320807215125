import TransferDetailList from "./TransferDetailList";

const transferDetailRoutes = [
  {
    path: "/TransferDetail/TransferDetail-list",
    component: TransferDetailList,
    permission: "Permissions.Transfers.View",
  },
];
export default transferDetailRoutes;
