import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  Icon,
  MenuItem,
  TablePagination,
  TextField,
  Typography,
  Grid,
  Box,
  Divider,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Breadcrumb, MatxLoading, SimpleCard } from "app/components";
import axios from "axios.js";
import React, { useCallback, useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { config } from "../../../../config";

function Row({ tableData }) {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <TableRow hover={true}>
        <TableCell>
          <IconButton aria-label="expand row" onClick={() => setOpen(!open)}>
            {open ? (
              <Icon color="error">arrow_drop_up</Icon>
            ) : (
              <Icon color="error">arrow_drop_down</Icon>
            )}
          </IconButton>
        </TableCell>

        <TableCell>{tableData.transferNo}</TableCell>
        <TableCell>
            {tableData.sourceName}
        </TableCell>
        <TableCell>{tableData.destinationName}</TableCell>
        <TableCell>
          <Typography style={{ wordBreak: "break-word" }}>
            {tableData.supplierName}
          </Typography>
        </TableCell>
        <TableCell>{tableData.totalAmount}</TableCell>
        <TableCell>{tableData.createdOn.substring(0, 10)}</TableCell>
      </TableRow>
      <TableRow hover={true}>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, width: "100%" }}
          colSpan={12}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <SimpleCard title="item">
              <Table size="small" aria-label="transfers">
                <TableHead>
                  <TableRow>
                    <TableCell>Transfer No</TableCell>
                    <TableCell>Product Name</TableCell>
                    <TableCell>Transfer Qty</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Created On</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.transferDetails.map((transferDetail) => (
                    <TableRow key={transferDetail.id}>
                      <TableCell>{transferDetail.transferNo}</TableCell>
                      <TableCell>{transferDetail.productName}</TableCell>
                      <TableCell>{transferDetail.transferQty}</TableCell>
                      <TableCell>{transferDetail.amount}</TableCell>
                      <TableCell>{tableData.createdOn.substring(0, 10)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </SimpleCard>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const TransferMultipleList = () => {
  const history = useHistory();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [reqBody, setReqBody] = useState({});
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  const [branches, setBranches] = useState({});
  const [suppliers, setSuppliers] = useState([]);
  const [transferObj, setTransferObj] = useState({});

  const fetchAPI = useCallback(
    async (body) => {
      let response = await axios.post(config.BASE_API + "/TransferMultiple/search", {
        page: page,
        rowsPerPage: rowsPerPage,
        keyword: body?.keyword,
        sourceBranchId: body?.sourceBranchId,
        destinationBranchId: body?.destinationBranchId,
        supplierId: body?.supplierId,
        fromDate: body?.fromDate,
        toDate: body?.toDate,
      });
      response = await response.data;
      setTransferObj(response.data);
      setLoading(false);
      if (response?.succeeded === false) {
        toast.error(response.message);
      }
    },
    [page, rowsPerPage]
  );
  async function fetchBranches() {
    let response = await axios.post(config.BASE_API + "/Branch/Search", {
      page: -1,
    });
    response = await response.data;
    setBranches(response.data);
  }
  async function fetchSupplier(keyword) {
    let response = await axios.post(config.BASE_API + "/Supplier/Search", {
      page: -1,
      keyword: keyword,
      orderBy: "name",
      isAscending: true,
    });
    response = await response.data;
    if (response?.succeeded == true) setSuppliers(response?.data?.item1);
  }
  const handleDateChangeFrom = (date, value) => {
    setSelectedDateFrom(date);
    setReqBody({
      ...reqBody,
      fromDate: date,
    });
  };
  const handleDateChangeTo = (date, value) => {
    setSelectedDateTo(date);
    setReqBody({
      ...reqBody,
      toDate: date,
    });
  };
  const handleChangeAutoSupplier = (event, newValue) => {
    setReqBody({
      ...reqBody,
      supplierId: newValue?.id,
    });
    event.persist();
  };
  const handleInputChangeAutoSupplier = (event, newValue) => {
    if (newValue) fetchSupplier(newValue);
    else setSuppliers([]);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    fetchAPI(reqBody);
    fetchBranches();
  }, [fetchAPI]);

  const handleSearch = (event) => {
    if (event?.nativeEvent?.submitter?.name === "search") {
      fetchAPI(reqBody);
    }
  };

  const handleChange = (event) => {
    event.persist();
    setReqBody({
      ...reqBody,
      [event.target.name]: event.target.value,
    });
  };

  const { keyword, fromDate, toDate, sourceBranchId, destinationBranchId, supplierId } =
    reqBody;

  return (
    <>
      <div className="m-sm-30">
        <div className="mb-sm-30">
          <Breadcrumb
            routeSegments={[
              { name: "Transfer", path: "/TransferMultiple/TransferMultiple-list" },
              { name: "Transfer List" },
            ]}
          />
        </div>
        <ValidatorForm onSubmit={handleSearch} onError={() => null}>
          <Box marginBottom={2}>
            <SimpleCard>
              <Grid container spacing={2}>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <TextField
                    className="m-2"
                    label="Search By Transfer No"
                    name="keyword"
                    size="small"
                    variant="outlined"
                    value={keyword || ""}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <TextField
                    select
                    required
                    className="m-2"
                    style={{ width: 300 }}
                    size="small"
                    variant="outlined"
                    label="Select Source Branch"
                    name="sourceBranchId"
                    value={sourceBranchId || ""}
                    onChange={handleChange}
                  >
                    <select value={sourceBranchId || ""}></select>
                    <MenuItem value="">Select Source Branch</MenuItem>
                    {branches?.item1?.map((branch, index) => (
                      <MenuItem value={branch?.id} key={index}>
                        {branch?.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <TextField
                    select
                    required
                    className="m-2"
                    style={{ width: 300 }}
                    size="small"
                    variant="outlined"
                    label="Select Destination Branch"
                    name="destinationBranchId"
                    value={destinationBranchId || ""}
                    onChange={handleChange}
                  >
                    <select value={destinationBranchId || ""}></select>
                    <MenuItem value="">Select Destination Branch</MenuItem>
                    {branches?.item1?.map((branch, index) => (
                      <MenuItem value={branch?.id} key={index}>
                        {branch?.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      className="m-2"
                      margin="none"
                      label="From"
                      inputVariant="outlined"
                      type="text"
                      size="small"
                      autoOk={true}
                      format="dd/MM/yyyy"
                      value={selectedDateFrom}
                      onChange={handleDateChangeFrom}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      className="m-2"
                      margin="none"
                      label="To"
                      inputVariant="outlined"
                      type="text"
                      size="small"
                      autoOk={true}
                      format="dd/MM/yyyy"
                      value={selectedDateTo}
                      onChange={handleDateChangeTo}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <Autocomplete
                    disablePortal
                    id="supplierId"
                    name="supplierId"
                    options={suppliers}
                    onChange={handleChangeAutoSupplier}
                    onInputChange={handleInputChangeAutoSupplier}
                    getOptionLabel={(option) => option?.name}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{ marginTop: 10 }}
                        label="Select Supplier"
                        size="small"
                        className="m-2"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <Button
                    className="m-2"
                    variant="contained"
                    color="primary"
                    type="submit"
                    name="search"
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </SimpleCard>
          </Box>
          {loading ? (
            <MatxLoading />
          ) : (
            <SimpleCard>
              <Grid container justify="space-between">
                <Grid item>
                  <Box marginBottom={2}>
                    <Typography variant="h6" component="h2">
                      Transfer List
                    </Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Box marginBottom={2}>
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      onClick={() => history.push("/TransferMultiple/TransferMultipleForm")}
                    >
                      + Add New Transfer
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              <Divider style={{ background: "gray" }} variant="middle" />
              <div className="w-full overflow-auto">
                <TableContainer style={{ width: "100%", overflowX: "auto" }}>
                  <Table className="whitespace-no-wrap" sx={{ minWidth: 1500 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell width="5%" className="px-0" align="left">Items</TableCell>
                        <TableCell width="8%" className="px-0" align="left" style={{ wordBreak: "break-word" }}>
                          Transfer No
                        </TableCell>
                        <TableCell width="12%" className="px-0" align="left" style={{ wordBreak: "break-word" }}>Source Branch Name</TableCell>
                        <TableCell width="15%" className="px-0" align="left" style={{ wordBreak: "break-word" }}>Destination Branch Name</TableCell>
                        <TableCell width="40%" className="px-0" align="left" style={{ wordBreak: "break-word" }}>Supplier Name</TableCell>
                        <TableCell width="10%" className="px-0" align="left" style={{ wordBreak: "break-word" }}>Total Amount</TableCell>
                        <TableCell width="10%" className="px-0" align="left" style={{ wordBreak: "break-word" }}>
                          Created On
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {transferObj?.item1.map((transfer, index) => (
                        <Row key={index} tableData={transfer} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <TablePagination
                  className="px-4"
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component="div"
                  count={transferObj?.item2 || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    "aria-label": "Previous Page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Next Page",
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </div>
            </SimpleCard>
          )}
        </ValidatorForm>
      </div>
    </>
  );
};

export default TransferMultipleList;
