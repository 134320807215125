import { Breadcrumb, MatxLoading, SimpleCard } from "app/components";
import React, { useCallback, useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import {
  Button,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Box,
  Grid,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Divider from "@material-ui/core/Divider";
import axios from "axios.js";
import { config } from "../../../../config";
import ConfirmMessage from "../../Configuration/Message/ConfirmMessage";
import { toast } from "react-toastify";

const StockList = () => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [reqBody, setReqBody] = useState({});
  const [stockObj, setStockObj] = useState({});
  const [branches, setBranches] = useState({});
  const [suppliers, setSuppliers] = useState([]);
  const [confirmMessage, setConfirmMessage] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fetchAPI = useCallback(
    async (body) => {
      let response = await axios.post(config.BASE_API + "/Stock/Search", {
        page: page,
        rowsPerPage: rowsPerPage,
        keyword: body?.keyword,
        branchId: body?.branchId,
        supplierIds: body?.supplierId ? [body?.supplierId] : [],
      });
      response = await response.data;
      setStockObj(response?.data);
      setLoading(false);
      if (response?.succeeded === false) {
        toast.error(response.message);
      }
    },
    [page, rowsPerPage]
  );

  function print(body) {
    axios({
      url: config.BASE_API + "/Stock/print",
      method: "post",
      data: {
        keyword: body?.keyword,
        branchId: body?.branchId,
        supplierIds: body?.supplierId ? [body?.supplierId] : [],
      },
      responseType: "blob", // important
    }).then((response) => {
      if (response?.data?.type === "text/plain") {
        window.alert("No Result Found");
        return;
      }
      //Create a Blob from the PDF Stream
      const file = new Blob([response.data], { type: "application/pdf" });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      const pdfWindow = window.open();
      pdfWindow.location.href = fileURL;
    });
  }

  async function fetchBranches() {
    let response = await axios.post(config.BASE_API + "/Branch/Search", {
      page: -1,
    });
    response = await response.data;
    setBranches(response.data);
  }

  async function fetchSupplier(keyword) {
    let response = await axios.post(config.BASE_API + "/Supplier/Search", {
      page: -1,
      keyword: keyword,
      orderBy: "name",
      isAscending: true,
    });
    response = await response.data;
    if (response?.succeeded == true) setSuppliers(response?.data?.item1);
  }

  useEffect(() => {
    fetchAPI(reqBody);
    fetchBranches();
    fetchSupplier();
  }, [fetchAPI]);

  const handleSearch = (event) => {
    // console.log("Button Name", event?.nativeEvent?.submitter?.name)
    if (event?.nativeEvent?.submitter?.name === "search") {
      fetchAPI(reqBody);
    } else {
      print(reqBody);
    }
  };

  const handleChange = (event) => {
    event.persist();
    setReqBody({
      ...reqBody,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeAutoSupplier = (event, newValue) => {
    setReqBody({
      ...reqBody,
      supplierId: newValue?.id,
    });
    event.persist();
  };
  const handleInputChangeAutoSupplier = (event, newValue) => {
    if (newValue) fetchSupplier(newValue);
    else setSuppliers([]);
  };

  const { keyword, branchId, supplierId } = reqBody;

  return (
    <>
      <div className="m-sm-30">
        <div className="mb-sm-30">
          <Breadcrumb
            routeSegments={[
              { name: "Stock", path: "/stock/stock-list" },
              { name: "Stock List" },
            ]}
          />
        </div>
        <ValidatorForm onSubmit={handleSearch} onError={() => null}>
          <Box mb={2}>
            <SimpleCard>
              <Grid container spacing={2}>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <TextField
                    className="mr-3"
                    label="Search By Keywords"
                    name="keyword"
                    size="small"
                    variant="outlined"
                    value={keyword || ""}
                    onChange={handleChange}
                    style={{ marginTop: 10 }}
                  />
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <TextField
                    select
                    className="mr-3 "
                    style={{ marginTop: 10, width: 300 }}
                    size="small"
                    variant="outlined"
                    label="Select Branch"
                    name="branchId"
                    validators={["required"]}
                    errormessages={["This field is required"]}
                    value={branchId || ""}
                    onChange={handleChange}
                  >
                    <select value={branchId || ""}></select>
                    <MenuItem value="">Select Branch</MenuItem>
                    {branches?.item1?.map((branch, index) => (
                      <MenuItem value={branch?.id} key={index}>
                        {branch?.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <Autocomplete
                    disablePortal
                    id="supplierId"
                    name="supplierId"
                    options={suppliers}
                    onChange={handleChangeAutoSupplier}
                    onInputChange={handleInputChangeAutoSupplier}
                    getOptionLabel={(option) => option?.name}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{ marginTop: 10 }}
                        label="Select Supplier"
                        size="small"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    name="search"
                    style={{ marginTop: 10 }}
                  >
                    Search
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    name="print"
                    style={{
                      marginTop: 10,
                      marginLeft: 10,
                    }}
                  >
                    Download Pdf
                  </Button>
                </Grid>
              </Grid>
            </SimpleCard>
          </Box>
          {loading ? (
            <MatxLoading />
          ) : (
            <SimpleCard title="Stock List">
              <Divider style={{ background: "gray" }} variant="middle" />
              <div className="w-full overflow-auto">
                <TableContainer style={{ width: "100%", overflowX: "auto" }}>
                  <Table style={{ minWidth: 1500 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell width="15%" className="px-0">Branch</TableCell>
                        <TableCell width="25%" className="px-0">Supplier</TableCell>
                        <TableCell width="30%" className="px-0">Product</TableCell>
                        <TableCell width="10%" className="px-0">Quantity</TableCell>
                        <TableCell width="20%" className="px-0">Value</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {stockObj?.item1.map((stock, index) => (
                        <TableRow key={index}>
                          <TableCell className="px-0 capitalize" align="left">
                            {stock.branchName}
                          </TableCell>
                          <TableCell className="px-0 capitalize" align="left">
                            {stock.supplierName}
                          </TableCell>
                          <TableCell className="px-0 capitalize" align="left">
                            {stock.productName +
                              " " +
                              stock.productPackSize +
                              " " +
                              stock.productCategory}
                          </TableCell>

                          <TableCell className="px-0 capitalize" align="left">
                            {stock.quantity}
                          </TableCell>
                          <TableCell className="px-0 capitalize" align="left">
                            {stock.value}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <ConfirmMessage
                  confirmMessage={confirmMessage}
                  setConfirmMessage={setConfirmMessage}
                />

                <TablePagination
                  className="px-4"
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component="div"
                  count={stockObj?.item2}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    "aria-label": "Previous Page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Next Page",
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </div>
            </SimpleCard>
          )}
        </ValidatorForm>
      </div>
    </>
  );
};

export default StockList;
