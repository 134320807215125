import React from "react";

const stockAdjustmentRoutes = [
  {
    path: "/stock-adjustment/stock-adjustment-add",
    component: React.lazy(() => import("./StockAdjustmentForm")),
     permission: "Permissions.StockAdjustments.Add",
  },
  {
    path: "/stock-adjustment/stock-adjustment-list",
    component: React.lazy(() => import("./StockAdjustmentList")),
    permission: "Permissions.StockAdjustments.View",
  },
];

export default stockAdjustmentRoutes;
