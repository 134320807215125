import React from "react";
import CustomerList from "./CustomerList";
const CustomerRoutes = [
  {
    path: "/Customer/Customer-list",
    component: CustomerList,
    permission: "Permissions.Customers.View",
  },
  {
    path: "/Customer/Customer/addNew",
    component: React.lazy(() => import("./CustomerForm")),
    permission: "Permissions.Customers.Add",
  },
  {
    path: "/Customer/:customerId",
    component: React.lazy(() => import("./CustomerForm")),
    permission: "Permissions.Customers.Update",
  },
];
export default CustomerRoutes;
