import React from "react";
import ExpenseList from './ExpenseList'
const expenseRoutes = [
{
    path: '/expense/expense-list',
    component: ExpenseList,
    permission: 'Permissions.Expenses.View'
},
{
    path: '/expense/expense/addNew',
    component: React.lazy(() => import('./ExpenseForm')),
    permission: 'Permissions.Expenses.Add'
},
{
    path: '/expense/:expenseId',
    component: React.lazy(() => import('./ExpenseForm')),
    permission: 'Permissions.Expenses.Update'
}
]
export default expenseRoutes