import React from "react";

const branchRoutes = [
{
    path: '/branch/branch-list',
    component: React.lazy(() => import('./BranchList')),
    permission: 'Permissions.Branches.View'
},
{
    path: '/branch/branch/addNew',
    component: React.lazy(() => import('./BranchForm')),
    permission: 'Permissions.Branches.Add'
},
{
    path: '/branch/:branchId',
    component: React.lazy(() => import('./BranchForm')),
    permission: 'Permissions.Branches.Update'
}
]
export default branchRoutes