export const config = {
    BASE_API: process.env.REACT_APP_BASE_API_URL,
    BASE_ROOT: process.env.REACT_APP_BASE_ROOT_URL 
}


export var firebaseConfig = {
    apiKey: 'AIzaSyBoUdiDtzQdC-m4nj9CPY5SvY7uKJGL71k',
    authDomain: 'matx-15ede.firebaseapp.com',
    databaseURL: 'https://matx-15ede.firebaseio.com',
    projectId: 'matx-15ede',
    storageBucket: 'matx-15ede.appspot.com',
    messagingSenderId: '348111707030',
    appId: '1:348111707030:web:70c4ca4eb3f1dbd18e1bb7',
    measurementId: 'G-806629YLNN',
}

export const auth0Config = {
    client_id: 'XmminWIs0S8gR3gIRBydYLWbF58x81vK',
    domain: 'matx.us.auth0.com',
}


