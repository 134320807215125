import CustomerBalanceReports from "./CustomerBalanceReports";
import SupplierBalanceReports from "./SupplierBalanceReports";

const reportRoutes = [
  {
    path: "/CustomerBalance/CustomerBalance-list",
    component: CustomerBalanceReports,
    // permission: 'Permissions.Stocks.View'
  },
  {
    path: "/SupplierBalance/SupplierBalance-List",
    component: SupplierBalanceReports,
  },
];
export default reportRoutes;
