import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  MenuItem,
  TablePagination,
  TextField,
  Typography,
  Grid,
  Box,
  Divider,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Breadcrumb, MatxLoading, SimpleCard } from "app/components";
import axios from "axios.js";
import React, { useCallback, useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { toast } from "react-toastify";
import { config } from "../../../../config";

function Row({ tableData }) {
  return (
    <React.Fragment>
      <TableRow hover={true}>
        <TableCell>{tableData.transferNo}</TableCell>
        <TableCell>
          <Typography style={{ wordBreak: "break-word" }}>
            {tableData.productName}
          </Typography>
        </TableCell>
        <TableCell>{tableData.transferQty}</TableCell>
        <TableCell>{tableData.amount}</TableCell>
        <TableCell>{tableData.createdOn.substring(0, 10)}</TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const TransferDetailList = () => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [reqBody, setReqBody] = useState({});
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  const [branches, setBranches] = useState({});
  const [transferDetailObj, setTransferDetailObj] = useState({});

  const handleDateChangeFrom = (date, value) => {
    setSelectedDateFrom(date);
    setReqBody({
      ...reqBody,
      fromDate: date,
    });
  };
  const handleDateChangeTo = (date, value) => {
    setSelectedDateTo(date);
    setReqBody({
      ...reqBody,
      toDate: date,
    });
  };
  const handleChangeAuto = (event, newValue) => {
    setReqBody({
      ...reqBody,
      productId: newValue?.id,
    });
    event.persist();
  };
  const handleInputChangeAuto = (event, newValue) => {
    if (reqBody?.supplierId) {
      fetchProduct(newValue, reqBody?.supplierId);
      event.persist();
    } else {
      if (newValue) fetchProduct(newValue, null);
      else setProducts([]);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fetchAPI = useCallback(
    async (body) => {
      let response = await axios.post(
        config.BASE_API + "/TransferDetail/search",
        {
          page: page,
          rowsPerPage: rowsPerPage,
          orderBy: "createdOn",
          keyword: body?.keyword,
          fromDate: body?.fromDate,
          toDate: body?.toDate,
          sourceBranchId: body?.sourceBranchId,
          destinationBranchId: body?.destinationBranchId,
          productId: body?.productId,
        }
      );
      response = await response.data;
      setTransferDetailObj(response.data);
      setLoading(false);
      if (response?.succeeded === false) {
        toast.error(response.message);
      }
    },
    [page, rowsPerPage]
  );

  async function fetchBranches() {
    let response = await axios.post(config.BASE_API + "/Branch/Search", {
      page: -1,
    });
    response = await response.data;
    setBranches(response.data);
  }
  async function fetchProduct(keyword, supplierId) {
    let response = await axios.post(config.BASE_API + "/Product/search", {
      page: -1,
      keyword: keyword,
      orderBy: "name",
      isAscending: true,
      supplierIds: supplierId ? [supplierId] : [],
    });
    response = await response?.data;
    if (response?.succeeded == true) setProducts(response?.data?.item1);
  }

  useEffect(() => {
    fetchAPI(reqBody);
    fetchBranches();
  }, [fetchAPI]);

  function print(body) {
    console.log("Testing Purpose for Print:",body);
    axios({
      url: config.BASE_API + "/TransferDetail/print",
      method: "post",
      data: {
        keyword: body?.keyword,
        orderBy: "createdOn",
        sourceBranchId: body?.sourceBranchId,
        destinationBranchId: body?.destinationBranchId,
        productId: body?.productId,
        fromDate: body?.fromDate,
        toDate: body?.toDate,
      },
      responseType: "blob", // important
    }).then((response) => {
        console.log("Test Response",response);
      if (response?.data?.type === "text/plain") {
        window.alert("No Result Found");
        return;
      }
      //Create a Blob from the PDF Stream
      const file = new Blob([response.data], { type: "application/pdf" });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      const pdfWindow = window.open();
      pdfWindow.location.href = fileURL;
    });
  }

  const handleSearch = (event) => {
    if (event?.nativeEvent?.submitter?.name === "search") {
      fetchAPI(reqBody);
    } else if (event?.nativeEvent?.submitter?.name === "print") {
      print(reqBody);
    }
  };

  const handleChange = (event) => {
    event.persist();
    setReqBody({
      ...reqBody,
      [event.target.name]: event.target.value,
    });
  };
  const { keyword, fromDate, toDate, sourceBranchId, destinationBranchId } =
    reqBody;

  return (
    <>
      <div className="m-sm-30">
        <div className="mb-sm-30">
          <Breadcrumb
            routeSegments={[
              {
                name: "Transfer Detail",
                path: "/TransferDetail/TransferDetail-list",
              },
              { name: "Transfer Detail List" },
            ]}
          />
        </div>
        <ValidatorForm onSubmit={handleSearch} onError={() => null}>
          <Box marginBottom={2}>
            <SimpleCard>
              <Grid container spacing={2}>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <TextField
                    className="m-2"
                    label="Search By Transfer No"
                    name="keyword"
                    size="small"
                    variant="outlined"
                    value={keyword || ""}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <TextField
                    select
                    required
                    className="m-2"
                    style={{ width: 300 }}
                    size="small"
                    variant="outlined"
                    label="Select Source Branch"
                    name="sourceBranchId"
                    value={sourceBranchId || ""}
                    onChange={handleChange}
                  >
                    <select value={sourceBranchId || ""}></select>
                    <MenuItem value="">Select Source Branch</MenuItem>
                    {branches?.item1?.map((branch, index) => (
                      <MenuItem value={branch?.id} key={index}>
                        {branch?.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <TextField
                    select
                    required
                    className="m-2"
                    style={{ width: 300 }}
                    size="small"
                    variant="outlined"
                    label="Select Destination Branch"
                    name="destinationBranchId"
                    value={destinationBranchId || ""}
                    onChange={handleChange}
                  >
                    <select value={destinationBranchId || ""}></select>
                    <MenuItem value="">Select Destination Branch</MenuItem>
                    {branches?.item1?.map((branch, index) => (
                      <MenuItem value={branch?.id} key={index}>
                        {branch?.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    name="productId"
                    options={products}
                    onChange={handleChangeAuto}
                    onInputChange={handleInputChangeAuto}
                    getOptionLabel={(option) =>
                      option.name +
                      " " +
                      option.packSize +
                      " " +
                      option.category.name +
                      " " +
                      option.supplier.name
                    }
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{ marginTop: 10 }}
                        label="Select Product"
                        size="small"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      className="m-2"
                      margin="none"
                      label="From"
                      inputVariant="outlined"
                      type="text"
                      size="small"
                      autoOk={true}
                      format="dd/MM/yyyy"
                      value={selectedDateFrom}
                      onChange={handleDateChangeFrom}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      className="m-2"
                      margin="none"
                      label="To"
                      inputVariant="outlined"
                      type="text"
                      size="small"
                      autoOk={true}
                      format="dd/MM/yyyy"
                      value={selectedDateTo}
                      onChange={handleDateChangeTo}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <Button
                    className="m-2"
                    variant="contained"
                    color="primary"
                    type="submit"
                    name="search"
                  >
                    Search
                  </Button>
                  <Button
                    className="m-2"
                    variant="contained"
                    color="primary"
                    type="submit"
                    name="print"
                  >
                    Download Pdf
                  </Button>
                </Grid>
              </Grid>
            </SimpleCard>
          </Box>
          {loading ? (
            <MatxLoading />
          ) : (
            <SimpleCard>
              <Grid container>
                <Grid item>
                  <Box marginBottom={2}>
                    <Typography variant="h6" component="h2">
                      Transfer Detail List
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Divider style={{ background: "gray" }} variant="middle" />
              <div className="w-full overflow-auto">
                <TableContainer style={{ width: "100%", overflowX: "auto" }}>
                  <Table className="whitespace-no-wrap" sx={{ minWidth: 1500 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ wordBreak: "break-word" }}>
                          Transfer No
                        </TableCell>
                        <TableCell style={{ wordBreak: "break-word" }}>
                          Product Name
                        </TableCell>
                        <TableCell style={{ wordBreak: "break-word" }}>
                          Transfer Qty
                        </TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell style={{ wordBreak: "break-word" }}>
                          Created On
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {transferDetailObj?.item1.map((transferDetail, index) => (
                        <Row key={index} tableData={transferDetail} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <TablePagination
                  className="px-4"
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component="div"
                  count={transferDetailObj?.item2 || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    "aria-label": "Previous Page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Next Page",
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </div>
            </SimpleCard>
          )}
        </ValidatorForm>
      </div>
    </>
  );
};

export default TransferDetailList;
