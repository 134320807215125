import React from "react";
import { GlobalCss, MatxLayout, MatxSuspense, MatxTheme } from "app/components";
import { AuthProvider } from "app/contexts/JWTAuthContext";
import { SettingsProvider } from "app/contexts/SettingsContext";
import history from "history.js";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Router, Switch } from "react-router-dom";
import "../fake-db";
import AuthGuard from "./auth/AuthGuard";
import AppContext from "./contexts/AppContext";
import { Store } from "./redux/Store";
import routes from "./RootRoutes";
import sessionRoutes from "./views/sessions/SessionRoutes";
import { ToastContainer } from "react-toastify";

const App = () => {
  return (
    <AppContext.Provider value={{ routes }}>
      <Provider store={Store}>
        <SettingsProvider>
          <MatxTheme>
            <GlobalCss />
            <BrowserRouter basename={process.env.PUBLIC_URL}>
              <Router history={history}>
                <AuthProvider>
                  <MatxSuspense>
                    <Switch>
                      {/* AUTHENTICATION PAGES (SIGNIN, SIGNUP ETC.) */}
                      {sessionRoutes.map((item, i) => (
                        <Route
                          key={i}
                          path={item.path}
                          component={item.component}
                        />
                      ))}
                      {/* AUTH PROTECTED DASHBOARD PAGES */}
                      <AuthGuard>
                        <MatxLayout /> {/* RETURNS <Layout1/> component */}
                      </AuthGuard>
                    </Switch>
                    <ToastContainer />
                  </MatxSuspense>
                </AuthProvider>
              </Router>
            </BrowserRouter>
          </MatxTheme>
        </SettingsProvider>
      </Provider>
    </AppContext.Provider>
  );
};

export default App;
