import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

import { makeStyles } from "@material-ui/core/styles";
import AuthContext from "app/contexts/JWTAuthContext";
import clsx from "clsx";
import history from "history.js";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  cardHolder: {
    background: "#1A2038",
  },
  card: {
    maxWidth: 800,
    borderRadius: 12,
    margin: "1rem",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const JwtLogin = () => {
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({
    // username: 'test',
    // password: '12300',
  });
  const [message, setMessage] = useState("");
  const { login } = useContext(AuthContext);

  const classes = useStyles();

  const handleChange = ({ target: { name, value } }) => {
    let temp = { ...userInfo };
    temp[name] = value;
    setUserInfo(temp);
  };

  const handleFormSubmit = async (event) => {
    setLoading(true);
    try {
      await login(userInfo.username, userInfo.password);
      history.push("/");
      //  setLoading(false)
    } catch (e) {
      // console.log(e)
      setMessage(e.message);
      setLoading(false);
    }
  };

  return (
    <div
      className={clsx(
        "flex justify-center items-center  min-h-full-screen",
        classes.cardHolder
      )}
    >
      <Card className={classes.card}>
        <Grid container>
          <Grid item lg={5} md={5} sm={5} xs={12}>
            <div className="p-8 flex justify-center items-center h-full">
              <img
                className="w-200"
                src="/assets/images/illustrations/dreamer.svg"
                alt=""
              />
            </div>
          </Grid>
          <Grid item lg={7} md={7} sm={7} xs={12}>
            <div className="p-8 h-full bg-light-gray relative">
              <ValidatorForm onSubmit={handleFormSubmit}>
                <TextValidator
                  className="mb-6 w-full"
                  variant="outlined"
                  size="small"
                  label="Username"
                  onChange={handleChange}
                  type="text"
                  name="username"
                  value={userInfo.username}
                  validators={["required"]}
                  errorMessages={[
                    "this field is required",
                    "email is not valid",
                  ]}
                />
                <TextValidator
                  className="mb-3 w-full"
                  label="Password"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  name="password"
                  type="password"
                  value={userInfo.password}
                  validators={["required"]}
                  errorMessages={["This field is required"]}
                />
                <FormControlLabel
                  className="mb-3 min-w-288"
                  name="agreement"
                  onChange={handleChange}
                  control={
                    <Checkbox
                      size="small"
                      onChange={({ target: { checked } }) =>
                        handleChange({
                          target: {
                            name: "agreement",
                            value: checked,
                          },
                        })
                      }
                      checked={userInfo.agreement || true}
                    />
                  }
                  label="Remeber me"
                />

                {message && <p className="text-error">{message}</p>}

                <div className="flex flex-wrap items-center mb-4">
                  <div className="relative">
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={loading}
                      type="submit"
                    >
                      Sign in
                    </Button>
                    {loading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                  <span className="mr-2 ml-5">or</span>
                  <Button
                    className="capitalize"
                    onClick={() => history.push("/session/signup")}
                  >
                    Sign up
                  </Button>
                </div>
                <Button
                  className="text-primary"
                  onClick={() => history.push("/session/forgot-password")}
                >
                  Forgot password?
                </Button>
              </ValidatorForm>
            </div>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default JwtLogin;
