import React from "react";
const purchaseReturnRoutes = [
  {
    path: "/purchase-return/purchase-return-list",
    component: React.lazy(() => import("./PurchaseReturnList")),
    permission: "Permissions.PurchaseReturns.View",
  },
  {
    path: "/purchase-return/purchase-return/addNew",
    component: React.lazy(() => import("./PurchaseReturnForm")),
    permission: "Permissions.PurchaseReturns.Add",
  },
];
export default purchaseReturnRoutes;
