import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import React from "react";
const Unauthorized = () => {
  return (
    <div className="flex justify-center items-center h-full-screen w-full">
      <div className="flex-column justify-center items-center max-w-320">
        <img
          className="mb-8 w-full"
          src="/assets/images/illustrations/401.gif"
          alt=""
        />
        {/* <Link to="/">
          <Button
            className=" mb-4  capitalize"
            variant="contained"
            color="primary"
          >
            Back to Dashboard
          </Button>
        </Link> */}
        <Link to="/session/signin">
          <Button className="capitalize" variant="contained" color="primary">
            Sign In Again
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default Unauthorized;
