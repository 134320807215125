import React from "react";
import { useCallback, useState, useEffect } from "react";
import axios from "axios.js";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  Table,
  TableBody,
  TableHead,
  TableContainer,
  TableRow,
  TableCell,
  Button,
  TablePagination,
  MenuItem,
  TextField,
  Box,
  Grid,
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { Autocomplete } from "@material-ui/lab";
import { toast } from "react-toastify";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Breadcrumb, MatxLoading, SimpleCard } from "app/components";
import { config } from "../../../../config";
import ConfirmMessage from "../../Configuration/Message/ConfirmMessage";

const SaleItemList = () => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reqBody, setReqBody] = useState({});
  const [saleItemObj, setSaleItemObj] = useState({});
  const [suppliers, setSuppliers] = useState([]);
  const [branches, setBranches] = useState({});
  const [products, setProducts] = useState([]);
  const [confirmMessage, setConfirmMessage] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const fetchAPI = useCallback(
    async (body) => {
      let response = await axios.post(config.BASE_API + "/SaleItem/search", {
        page: page,
        rowsPerPage: rowsPerPage,
        orderBy: "createdOn",
        fromDate: body?.fromDate,
        toDate: body?.toDate,
        branchId: body?.branchId,
        supplierId: body?.supplierId,
        productId: body?.productId,
      });
      response = await response.data;
      setSaleItemObj(response.data);
      setLoading(false);
      if (response?.succeeded === false) {
        toast.error(response.message);
      }
    },
    [page, rowsPerPage]
  );
  function print(body) {
    if (body?.branchId) {
      axios({
        url: config.BASE_API + "/SaleItem/print",
        method: "post",
        data: {
          fromDate: body?.fromDate,
          toDate: body?.toDate,
          branchId: body?.branchId,
          supplierId: body?.supplierId,
          productId: body?.productId,
          orderBy: "createdOn",
          page: -1,
        },
        responseType: "blob", // important
      }).then((response) => {
        if (response?.data?.type === "text/plain") {
          window.alert("No Result Found");
          return;
        }
        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], { type: "application/pdf" });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        const pdfWindow = window.open();
        pdfWindow.location.href = fileURL;
      });
    } else {
      window.alert("Please Select Branch");
    }
  }

  async function fetchBranches() {
    let response = await axios.post(config.BASE_API + "/Branch/Search", {
      page: -1,
    });
    response = await response.data;
    setBranches(response.data);
  }

  async function fetchSupplier(keyword) {
    let response = await axios.post(config.BASE_API + "/Supplier/Search", {
      page: -1,
      keyword: keyword,
      orderBy: "name",
      isAscending: true,
    });
    response = await response.data;
    if (response?.succeeded == true) setSuppliers(response?.data?.item1);
  }

  async function fetchProduct(keyword,supplierId) {
    let response = await axios.post(config.BASE_API + "/Product/search", {
      page: -1,
      keyword: keyword,
      orderBy: "name",
      isAscending: true,
      supplierIds:supplierId?[supplierId]:[]
    });
    response = await response?.data;
    if (response?.succeeded == true) setProducts(response?.data?.item1);
  }

  const handleDateChangeFrom = (date, value) => {
    setSelectedDateFrom(date);
    setReqBody({
      ...reqBody,
      fromDate: date,
    });
  };

  const handleDateChangeTo = (date, value) => {
    setSelectedDateTo(date);
    setReqBody({
      ...reqBody,
      toDate: date,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearch = (event) => {
    if (event?.nativeEvent?.submitter?.name === "search") {
      fetchAPI(reqBody);
    } else if (event?.nativeEvent?.submitter?.name === "print") {
      print(reqBody);
    }
  };

  const handleChange = (event) => {
    event.persist();
    setReqBody({
      ...reqBody,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeAuto = (event, newValue) => {
    setReqBody({
      ...reqBody,
      productId: newValue?.id,
    });
    event.persist();
  };
  const handleInputChangeAuto = (event, newValue) => {
    if (reqBody?.supplierId) {
      fetchProduct(newValue,reqBody?.supplierId);
      event.persist();
    } else {
      if (newValue) fetchProduct(newValue,null);
      else setProducts([]);
    }
   
  };
  const handleChangeAutoSupplier = (event, newValue) => {
    setReqBody({
      ...reqBody,
      supplierId: newValue?.id,
    });
    event.persist();
  };
  const handleInputChangeAutoSupplier = (event, newValue) => {
    if (newValue) fetchSupplier(newValue);
    else setSuppliers([]);
  };

  useEffect(() => {
    fetchAPI(reqBody);
    fetchBranches();
    fetchSupplier();
  }, [fetchAPI]);

  const { fromDate, toDate, branchId, supplierId, productId } = reqBody;

  return (
    <>
      <div className="m-sm-30">
        <div className="mb-sm-30">
          <Breadcrumb
            routeSegments={[
              {
                name: "Item Wise Sale Details",
                path: "/SaleItem/sale-item-list",
              },
              { name: "Item Wise Sale Details" },
            ]}
          />
        </div>
        <ValidatorForm onSubmit={handleSearch} onError={() => null}>
          <Box mb={2}>
            <SimpleCard>
              <Grid container spacing={2}>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <TextField
                    select
                    className="mr-2"
                    style={{ marginTop: 10, width: 300 }}
                    size="small"
                    variant="outlined"
                    label="Select Branch"
                    name="branchId"
                    validators={["required"]}
                    errormessages={["This field is required"]}
                    value={branchId || ""}
                    onChange={handleChange}
                  >
                    <select value={branchId || ""}></select>
                    <MenuItem value="">Select Branch</MenuItem>
                    {branches?.item1?.map((branch, index) => (
                      <MenuItem value={branch?.id} key={index}>
                        {branch?.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <Autocomplete
                    disablePortal
                    id="supplierId"
                    name="supplierId"
                    options={suppliers}
                    onChange={handleChangeAutoSupplier}
                    onInputChange={handleInputChangeAutoSupplier}
                    getOptionLabel={(option) => option?.name}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{ marginTop: 10 }}
                        label="Select Supplier"
                        size="small"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    name="productId"
                    options={products}
                    onChange={handleChangeAuto}
                    onInputChange={handleInputChangeAuto}
                    getOptionLabel={(option) =>
                      option.name +
                      " " +
                      option.packSize +
                      " " +
                      option.category.name +
                      " " +
                      option.supplier.name
                    }
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{ marginTop: 10 }}
                        label="Select Product"
                        size="small"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      className="m-2"
                      margin="none"
                      label="From"
                      inputVariant="outlined"
                      type="text"
                      size="small"
                      autoOk={true}
                      format="dd/MM/yyyy"
                      value={selectedDateFrom}
                      onChange={handleDateChangeFrom}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      className="m-2"
                      margin="none"
                      label="To"
                      inputVariant="outlined"
                      type="text"
                      size="small"
                      autoOk={true}
                      format="dd/MM/yyyy"
                      value={selectedDateTo}
                      onChange={handleDateChangeTo}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <Button
                    className="m-2"
                    variant="contained"
                    color="primary"
                    type="submit"
                    name="search"
                  >
                    Search
                  </Button>
                  <Button
                    className="m-2"
                    variant="contained"
                    color="primary"
                    type="submit"
                    name="print"
                    style={{ marginTop: 10, marginLeft: 10 }}
                  >
                    PDF
                  </Button>
                </Grid>
              </Grid>
            </SimpleCard>
          </Box>
          {loading ? (
            <MatxLoading />
          ) : (
            <SimpleCard title="Item Wise Sale Details">
              <Divider style={{ background: "gray" }} variant="middle" />
              <TableContainer style={{ width: "100%", overflowX: "auto" }}>
                <Table style={{ minWidth: 1500 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell width="20%" className="px-0">
                        Sale No
                      </TableCell>
                      <TableCell width="30%" className="px-0">
                        Product Name
                      </TableCell>
                      <TableCell width="10%" className="px-0">
                        Price
                      </TableCell>
                      <TableCell width="10%" className="px-0">
                        Quantity
                      </TableCell>
                      <TableCell width="10%" className="px-0">
                        Amount
                      </TableCell>
                      <TableCell width="10%" className="px-0">
                        COGS
                      </TableCell>
                      <TableCell width="10%" className="px-0">
                        Created On
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {saleItemObj?.item1?.map((saleItem, index) => (
                      <TableRow key={index}>
                        <TableCell className="px-0 capitalize" align="left">
                          {saleItem.saleNo}
                        </TableCell>
                        <TableCell className="px-0 capitalize" align="left">
                          {saleItem.option}
                        </TableCell>
                        <TableCell className="px-0 capitalize" align="left">
                          {saleItem.price}
                        </TableCell>
                        <TableCell className="px-0 capitalize" align="left">
                          {saleItem.quantity}
                        </TableCell>
                        <TableCell className="px-0 capitalize" align="left">
                          {saleItem.amount}
                        </TableCell>
                        <TableCell className="px-0 capitalize" align="left">
                          {saleItem.itemCogs}
                        </TableCell>
                        <TableCell className="px-0 capitalize" align="left">
                          {saleItem.createdOn.substring(0, 10)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <ConfirmMessage
                confirmMessage={confirmMessage}
                setConfirmMessage={setConfirmMessage}
              />
              <TablePagination
                className="px-4"
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={saleItemObj?.item2 || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  "aria-label": "Previous Page",
                }}
                nextIconButtonProps={{
                  "aria-label": "Next Page",
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </SimpleCard>
          )}
        </ValidatorForm>
      </div>
    </>
  );
};
export default SaleItemList;
