import ProfitLossList from "./ProfitLossList";

const ProfitLossRoutes = [
  {
    path: "/profitLoss/profitLoss-list",
    component: ProfitLossList,
    permission: "Permissions.Reports.PL",
  },
];
export default ProfitLossRoutes;
