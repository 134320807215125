import React, { useContext, useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import AppContext from "../contexts/AppContext";
import useAuth from "app/hooks/useAuth";

const getUserRoleAuthStatus = (pathname, role, routes) => {
  const matched = routes.find((r) => r.path === pathname);
  const authenticated =
    matched && matched.auth && matched.auth.length
      ? matched.auth.includes(role)
      : true;
  console.log(matched, role);
  return authenticated;
};

const getUserPermissionAuthStatus = (
  pathname,
  allowedData,
  routes,
  permissions
) => {
  const matched = routes.find((r) => r.path === pathname);
  // console.log(matched)
  const authenticated =
    matched && matched.permission && matched.permission.length
      ? permissions.includes(matched.permission)
      : true;
  // console.log("auth guard auth",authenticated)
  //allowedData.some(a => permissions.includes(a)
  return authenticated;
};

const AuthGuard = ({ children }) => {
  const { isAuthenticated, username, role, permissions, loggedOut } = useAuth();
  const [previousRoute, setPreviousRoute] = useState(null);
  const { pathname } = useLocation();
  const { routes } = useContext(AppContext);
  const isUserPermissionAuthenticated = getUserPermissionAuthStatus(
    pathname,
    role,
    routes,
    permissions
  );

  let authenticated = isAuthenticated && isUserPermissionAuthenticated;

  useEffect(() => {
    if (previousRoute !== null) setPreviousRoute(pathname);
  }, [pathname, previousRoute]);

  if (authenticated) return <>{children}</>;
  else {
    //for logging out
    if (loggedOut)
      return (
        <Redirect
          to={{
            pathname: "/session/signin",
            state: { redirectUrl: previousRoute },
          }}
        />
      );
    return (
      <Redirect
        to={{
          pathname: "/session/401",
          state: { redirectUrl: previousRoute },
        }}
      />
    );
  }
};

export default AuthGuard;
