import SupplierLedgerList from "./SupplierLedgerList";

const SupplierLedgerRoutes = [
  {
    path: "/supplierLedger/supplierLedger-list",
    component: SupplierLedgerList,
    //permission: 'Permissions.Reports.PL'
  },
];
export default SupplierLedgerRoutes;
