import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  Icon,
  MenuItem,
  TablePagination,
  TextField,
  Grid,
  Box,
  Typography,
  Divider,
} from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Breadcrumb, SimpleCard } from "app/components";
import axios from "axios.js";
import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { toast } from "react-toastify";
import { config } from "../../../../config";
import ConfirmMessage from "../../Configuration/Message/ConfirmMessage";
import SaleReturn from "./SaleReturn";

function Row({ row }) {
  const [open, setOpen] = useState(false);
  const [openReturn, setReturn] = useState(false);
  const [returnButton, setReturnButtonShow] = useState(true);

  function downloadInvoice(saleId) {
    axios({
      url: config.BASE_API + "/Sale/invoice/" + saleId,
      method: "get",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        "Invoice" + new Date().toLocaleString() + ".pdf"
      );
      document.body.appendChild(link);
      link.click();
    });
  }

  // const expiry = (expDate) => {
  //   if (expDate == null) return null;
  //   return expDate.toString().substring(0, 10);
  // };

  const handleReturn = () => {
    setReturn(true);
    setReturnButtonShow(false);
  };

  return (
    <React.Fragment>
      <TableRow hover={true}>
        <TableCell width="5%" size="small" colSpan={1}>
          <IconButton aria-label="expand row" onClick={() => setOpen(!open)}>
            {open ? (
              <Icon color="error">arrow_drop_up</Icon>
            ) : (
              <Icon color="error">arrow_drop_down</Icon>
            )}
          </IconButton>
        </TableCell>

        <TableCell width="5%">{row.saleNo}</TableCell>
        <TableCell width="8%">
          {row.saleDate.toString().substring(0, 10)}
        </TableCell>
        <TableCell width="10%">{row.customerName}</TableCell>
        <TableCell width="10%">{row.branchName}</TableCell>
        <TableCell width="10%">{row.subtotal}</TableCell>
        <TableCell width="5%">{row.discountInPercentage.toFixed(2)}</TableCell>
        <TableCell width="5%">{row.discount}</TableCell>
        <TableCell width="5%">{row.payableAmount}</TableCell>
        <TableCell width="5%"> {row.paidAmount}</TableCell>
        <TableCell width="5%">{row.dueAmount}</TableCell>
        <TableCell width="5%">{row.totalSaleReturnedAmount}</TableCell>
        <TableCell width="7%">{row.createdOn.substring(0, 10)}</TableCell>
        <TableCell width="10%">
          {returnButton ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleReturn}
              name="Return"
              size="small"
              style={{ marginTop: 10, marginLeft: 10 }}
            >
              {row.paidAmount==0?'Return By Credit':'Return By Cash'}
            </Button>
          ) : null}
          {openReturn ? <SaleReturn values={row} /> : null}
        </TableCell>
        <TableCell width="5%">
          {" "}
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => downloadInvoice(row.id, e)}
            name="Download"
            size="small"
            style={{ marginTop: 10, marginLeft: 10 }}
          >
            <Icon>archive</Icon>
          </Button>
        </TableCell>

        {/* <TableCell className="px-0">
          <IconButton
            onClick={() => {
              history.push("/sale/" + row.id, { sale: row });
            }}
          >
            <Icon color="primary">edit</Icon>
          </IconButton>
        </TableCell> */}
      </TableRow>
      <TableRow hover={true}>
        <TableCell
          size="small"
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={7}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <SimpleCard title="item">
              <Table size="small" aria-label="sales">
                <TableHead>
                  <TableRow>
                    <TableCell>Product</TableCell>
                    <TableCell>Sale Price</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Return Qty</TableCell>
                    <TableCell>Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.saleItems.map((saleItem) => (
                    <TableRow key={saleItem.id}>
                      <TableCell>{saleItem.option}</TableCell>
                      <TableCell>{saleItem.price}</TableCell>
                      <TableCell>{saleItem.quantity}</TableCell>
                      <TableCell>{saleItem.returnQuantity}</TableCell>
                      <TableCell>{saleItem.amount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </SimpleCard>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const SaleList = () => {
  const [customerList, setCustomer] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [saleObj, setSaleObj] = useState({});
  const [reqBody, setReqBody] = useState({});
  const [branch, setBranches] = useState({});
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  const [confirmMessage, setConfirmMessage] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const handleDateChangeFrom = (date, value) => {
    setSelectedDateFrom(date);
    setReqBody({
      ...reqBody,
      fromDate: date,
    });
  };

  async function fetchCustomer(customerIds) {
    let response = await axios.post(config.BASE_API + "/Customer/search", {
      page: -1,
      customerIds,
    });
    response = await response?.data;
    if (response?.data?.item1) {
      setCustomer(response?.data?.item1);
    } else {
      alert("No Customer Found");
    }
  }

  const handleChangeCustomer = (event, value) => {
    setReqBody({
      ...reqBody,
      customerId: value?.id,
    });
    event.persist();
  };

  const handleDateChangeTo = (date, value) => {
    setSelectedDateTo(date);
    setReqBody({
      ...reqBody,
      toDate: date,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const remove = (id) => {
  //   setConfirmMessage({
  //     ...confirmMessage,
  //     isOpen: false,
  //   });
  //   axios.delete(config.BASE_API + "/sale/" + id).then((res) => {
  //     if (res?.data?.succeeded === true) {
  //       toast.error(res?.data?.message);
  //       fetchAPI();
  //     } else {
  //       toast.error(res?.data?.message);
  //     }
  //   });
  // };

  const fetchAPI = useCallback(
    async (body) => {
      let response = await axios.post(config.BASE_API + "/Sale/Search", {
        page: page,
        rowsPerPage: rowsPerPage,
        keyword: body?.keyword,
        fromDate: body?.fromDate,
        toDate: body?.toDate,
        branchId: body?.branchId,
        customerId: body?.customerId,
      });
      response = await response.data;
      setSaleObj(response.data);
      setLoading(false);
      if (response?.succeeded === false) {
        toast.error(response.message);
      }
    },
    [page, rowsPerPage]
  );

  async function fetchBranches() {
    let response = await axios.post(config.BASE_API + "/Branch/Search", {
      page: -1,
    });
    response = await response.data;
    setBranches(response.data);
  }

  function print(item) {
    axios({
      url: config.BASE_API + "/Sale/print",
      method: "post",
      data: {
        keyword: item?.keyword,
        branchId: reqBody?.branchId,
        fromDate: fromDate,
        toDate: toDate,
        customerId: reqBody?.customerId,
        page: -1,
      },
      responseType: "blob", // important
    }).then((response) => {
      if (response?.data?.type === "text/plain") {
        window.alert("No Result Found");
        return;
      }
      //Create a Blob from the PDF Stream
      const file = new Blob([response.data], { type: "application/pdf" });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      const pdfWindow = window.open();
      pdfWindow.location.href = fileURL;
    });
  }

  function dayWiseSaleReportPrint(item) {
    axios({
      url: config.BASE_API + "/Sale/day-wise-sale-summary",
      method: "post",
      data: {
        keyword: item?.keyword,
        branchId: reqBody?.branchId,
        fromDate: fromDate,
        toDate: toDate,
        customerId: reqBody?.customerId,
        page: -1,
      },
      responseType: "blob", // important
    }).then((response) => {
      if (response?.data?.type === "text/plain") {
        window.alert("No Result Found");
        return;
      }
      //Create a Blob from the PDF Stream
      const file = new Blob([response.data], { type: "application/pdf" });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      const pdfWindow = window.open();
      pdfWindow.location.href = fileURL;
    });
  }

  const handleSearch = (event) => {
    if (event?.nativeEvent?.submitter?.name === "search") {
      fetchAPI(reqBody);
    } else if (event?.nativeEvent?.submitter?.name === "print") {
      print(reqBody);
    } else if (
      event?.nativeEvent?.submitter?.name === "dayWiseSaleReportPrint"
    ) {
      dayWiseSaleReportPrint(reqBody);
    }
  };

  const handleChange = (event) => {
    event.persist();
    setReqBody({
      ...reqBody,
      [event.target.name]: event.target.value,
    });
  };

  async function fetchCustomer() {
    let response = await axios.post(config.BASE_API + "/Customer/search", {
      page: -1,
      orderBy: "name",
      isAscending: true,
    });
    response = await response?.data;
    // console.log("Customer=====******======>", response?.data?.item1)
    setCustomer(response?.data?.item1);
  }

  const { keyword, fromDate, toDate, branchId } = reqBody;

  useEffect(() => {
    fetchAPI();
    fetchCustomer();
    fetchBranches();
  }, [fetchAPI]);

  return (
    <>
      <div className="m-sm-30">
        <div className="mb-sm-30">
          <Breadcrumb
            routeSegments={[
              { name: "sale", path: "/sale/sale-list" },
              { name: "sale List" },
            ]}
          />
        </div>
        <ValidatorForm onSubmit={handleSearch} onError={() => null}>
          <Box marginBottom={2}>
            <SimpleCard>
              <Autocomplete
                id="combo-box-demo"
                name="customerId"
                style={{ marginTop: 10 }}
                size="small"
                variant="outlined"
                options={customerList}
                onChange={handleChangeCustomer}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    className="mr-3 "
                    style={{ marginTop: 10, width: 300 }}
                    size="small"
                    variant="outlined"
                    {...params}
                    label="Customer"
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              />
              <TextField
                className="mr-3"
                label="Search By Keywords"
                name="keyword"
                size="small"
                variant="outlined"
                value={keyword || ""}
                onChange={handleChange}
                style={{ marginTop: 10 }}
              />

              <TextField
                select
                className="mr-3 "
                style={{ marginTop: 10, width: 300 }}
                size="small"
                variant="outlined"
                label="Select Branch"
                name="branchId"
                validators={["required"]}
                errormessages={["This field is required"]}
                value={branchId || ""}
                onChange={handleChange}
              >
                <select value={branchId || ""}></select>
                {branch?.item1?.map((item, index) => (
                  <MenuItem value={item?.id} key={index}>
                    {item?.name}
                  </MenuItem>
                ))}
              </TextField>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className="m-2"
                  margin="none"
                  label="From"
                  inputVariant="outlined"
                  type="text"
                  size="small"
                  autoOk={true}
                  format="dd/MM/yyyy"
                  value={selectedDateFrom}
                  onChange={handleDateChangeFrom}
                />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className="m-2"
                  margin="none"
                  label="To"
                  inputVariant="outlined"
                  type="text"
                  size="small"
                  autoOk={true}
                  format="dd/MM/yyyy"
                  value={selectedDateTo}
                  onChange={handleDateChangeTo}
                />
              </MuiPickersUtilsProvider>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                name="search"
                style={{ marginTop: 10 }}
              >
                Search
              </Button>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                name="print"
                style={{ marginTop: 10, marginLeft: 10 }}
              >
                Download Pdf
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                name="dayWiseSaleReportPrint"
                style={{ marginTop: 10, marginLeft: 10 }}
              >
                Day Wise Sale Summary (Pdf)
              </Button>
            </SimpleCard>
          </Box>
          {loading ? (
            "Loading"
          ) : (
            <SimpleCard title="Sale List">
              <Divider style={{ background: "gray" }} variant="middle" />
              <div className="w-full">
                {/* <Button
                className="mt-4"
                color="primary"
                variant="contained"
                size="small"
                onClick={() => history.push("/sale/sale/addNew")}
              >
                + Add New sale
              </Button> */}
                <TableContainer style={{ width: "100%", overflowX: "auto" }}>
                  <Table style={{ minWidth: 1500 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell width="5%">Items</TableCell>
                        <TableCell width="5%">Sale No</TableCell>
                        <TableCell width="8%">Date</TableCell>
                        <TableCell width="10%">Customer</TableCell>
                        <TableCell width="10%">Branch</TableCell>
                        <TableCell width="10%">Subtotal</TableCell>
                        <TableCell width="5%">Discount(%)</TableCell>
                        <TableCell width="5%">Discount</TableCell>
                        <TableCell width="5%">Payable</TableCell>
                        <TableCell width="5%"> Paid</TableCell>
                        <TableCell width="5%">Due</TableCell>
                        <TableCell width="5%">Sale Return</TableCell>
                        <TableCell width="7%">Created On</TableCell>
                        <TableCell width="10%" align="center">
                          Return
                        </TableCell>
                        <TableCell width="5%">Invoice</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {saleObj?.item1.map((subscriber, index) => (
                        <Row key={index} row={subscriber} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <ConfirmMessage
                confirmMessage={confirmMessage}
                setConfirmMessage={setConfirmMessage}
              />
              <TablePagination
                className="px-4"
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={saleObj?.item2}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  "aria-label": "Previous Page",
                }}
                nextIconButtonProps={{
                  "aria-label": "Next Page",
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </SimpleCard>
          )}
        </ValidatorForm>
      </div>
    </>
  );
};
export default SaleList;
