import React from 'react'
import useSettings from 'app/hooks/useSettings'
import {config} from '../../../config'
import useAuth from "app/hooks/useAuth";
const MatxLogo = ({ className }) => {
     const { logo } = useAuth();
    const { settings } = useSettings()
    const theme = settings.themes[settings.activeTheme]

    return <img src={config.BASE_ROOT + "/"+logo} alt="Logo"/>;
}

export default MatxLogo
