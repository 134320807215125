import React from "react";
import MeasurementUnitList from './MeasurementUnitList'
const measurementRoutes = [
{
    path: '/measurementUnit/measurementUnit-list',
    component: MeasurementUnitList,
    permission: 'Permissions.MeasurementUnits.View'
},
{
    path: '/measurementUnit/measurementUnit/addNew',
    component: React.lazy(() => import('./MeasurementUnitForm')),
    permission: 'Permissions.MeasurementUnits.Add'
},
{
    path: '/measurementUnit/:measurementUnitId',
    component: React.lazy(() => import('./MeasurementUnitForm')),
    permission: 'Permissions.MeasurementUnits.Update'
}
]
export default  measurementRoutes
