import CustomerLedgerList from "./CustomerLedgerList";

const CustomerLedgerRoutes = [
  {
    path: "/customerLedger/customerLedger-list",
    component: CustomerLedgerList,
    //permission: 'Permissions.Reports.PL'
  },
];
export default CustomerLedgerRoutes;
