import React from "react";
import UserList from "./UserList";
const userRoutes = [
  {
    path: "/user/user-list",
    component: UserList,
    permission: "Permissions.Users.View",
  },
  {
    path: "/user/user/addNew",
    component: React.lazy(() => import("./UserForm")),
    permission: "Permissions.Users.Create",
  },
  {
    path: "/user/:userId",
    component: React.lazy(() => import("./UserForm")),
    permission: "Permissions.Users.Edit",
  },
];
export default userRoutes;
