import TransferMultipleForm from "./TransferMultipleForm";
import TransferMultipleList from "./TransferMultipleList";

const transferMultipleRoutes = [
  {
    path: "/TransferMultiple/TransferMultipleForm",
    component: TransferMultipleForm,
    permission: "Permissions.Transfers.Add",
  },
  {
    path: "/TransferMultiple/TransferMultiple-list",
    component: TransferMultipleList,
    permission: "Permissions.Transfers.View",
  },
];
export default transferMultipleRoutes;
