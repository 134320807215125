import TransferForm from "./TransferForm";
import TransferList from "./TransferList";

const transferRoutes = [
  {
    path: "/TransferItem/TransferItemForm",
    component: TransferForm,
    permission: "Permissions.Transfers.Add",
  },
  {
    path: "/Transfer/Transfer-list",
    component: TransferList,
    permission: "Permissions.Transfers.View",
  },
];
export default transferRoutes;
