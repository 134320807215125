import {
  Button,
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Grid,
  Box,
  Typography,
  Divider,
} from "@material-ui/core";
import { Breadcrumb, SimpleCard } from "app/components";
import axios from "axios.js";
import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { config } from "../../../../config";
import ConfirmMessage from "../Message/ConfirmMessage";

const ProductList = () => {
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(true);
  const [reqBody, setReqBody] = useState({});
  const [productObj, setProductObj] = useState({});

  const [confirmMessage, setConfirmMessage] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const remove = (id) => {
    setConfirmMessage({
      ...confirmMessage,
      isOpen: false,
    });
    axios.post(config.BASE_API + "/Product/" + id).then((res) => {
      if (res?.data?.succeeded === true) {
        toast.error(res?.data?.message);
        fetchAPI();
      } else {
        toast.info(res?.data?.message);
      }
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fetchAPI = useCallback(
    async (item) => {
      let response = await axios.post(config.BASE_API + "/Product/Search", {
        page: page,
        rowsPerPage: rowsPerPage,
        keyword: item?.keyword,
      });
      response = await response.data;
      if (response?.succeeded === false) {
        toast.error(response.message);
      }
      setProductObj(response.data);
      setLoading(false);
    },
    [page, rowsPerPage]
  );

  const handleSearch = (event) => {
    fetchAPI(reqBody);
  };

  const handleChange = (event) => {
    event.persist();
    setReqBody({
      ...reqBody,
      [event.target.name]: event.target.value,
    });
  };

  const { keyword } = reqBody;

  useEffect(() => {
    fetchAPI();
  }, [fetchAPI]);

  return (
    <>
      <div className="m-sm-30">
        <div className="mb-sm-30">
          <Breadcrumb
            routeSegments={[
              { name: "Product", path: "/product/product-list" },
              { name: "Product List" },
            ]}
          />
        </div>
        <ValidatorForm onSubmit={handleSearch} onError={() => null}>
          <Box marginBottom={2}>
            <SimpleCard>
              <TextField
                className="mr-3"
                label="Search By Keywords"
                name="keyword"
                size="small"
                variant="outlined"
                value={keyword || ""}
                onChange={handleChange}
              />
              <Button variant="contained" color="primary" type="submit">
                Search
              </Button>
            </SimpleCard>
          </Box>

          {loading ? (
            "Loading"
          ) : (
            <SimpleCard>
              <Grid container justify="space-between">
                <Grid item>
                  <Box marginBottom={2}>
                    <Typography variant="h6" component="h2">Product List</Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Box marginBottom={2}>
                  <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={() => history.push("/product/product/addNew")}
                >
                  + Add New Product
                </Button>
                  </Box>
                </Grid>
              </Grid>
              <Divider style={{background:"gray"}} variant="middle" />
              <div className="w-full overflow-auto">
                <TableContainer style={{ width: "100%", overflowX: "auto" }}>
                  <Table style={{ minWidth: 1000 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>BarCode</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell align="center">Pack Size</TableCell>
                        <TableCell>Category</TableCell>
                        <TableCell>Generic</TableCell>
                        <TableCell>Supplier</TableCell>
                        <TableCell align="center">Meas.Unit</TableCell>
                        <TableCell
                          align="center"
                          style={{ wordBreak: "break-word" }}
                        >
                          Purchase Price
                        </TableCell>
                        <TableCell align="center">MRP</TableCell>
                        <TableCell align="center">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {productObj?.item1.map((subscriber, index) => (
                        <TableRow
                          hover={true}
                          key={index}
                          style={{ height: 10 }}
                        >
                          <TableCell align="left">
                            {subscriber.barcode}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ wordBreak: "break-word" }}
                          >
                            {subscriber.name}
                          </TableCell>
                          <TableCell align="center">
                            {subscriber.packSize}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ wordBreak: "break-word" }}
                          >
                            {subscriber.category?.name}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ wordBreak: "break-word" }}
                          >
                            {subscriber.generic?.name}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ wordBreak: "break-word" }}
                          >
                            {subscriber.supplier?.name}
                          </TableCell>
                          <TableCell align="center">
                            {subscriber.measurementUnit?.name}
                          </TableCell>
                          <TableCell align="center">
                            {subscriber.purchasePrice}
                          </TableCell>
                          <TableCell align="center">{subscriber.mrp}</TableCell>

                          <TableCell
                            align="center"
                            style={{
                              wordBreak: "break-word",
                              display: "inline-flex",
                            }}
                          >
                            <Tooltip title="Edit">
                              <IconButton
                                onClick={() => {
                                  history.push("/product/" + subscriber.id);
                                }}
                              >
                                <Icon color="primary">edit</Icon>
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <IconButton
                                onClick={() => {
                                  setConfirmMessage({
                                    isOpen: true,
                                    title:
                                      "Are you sure to delete this record?",
                                    subTitle: "You can't undo this operation",
                                    onConfirm: () => {
                                      remove(subscriber.id);
                                    },
                                  });
                                }}
                              >
                                <Icon color="error">close</Icon>
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <ConfirmMessage
                  confirmMessage={confirmMessage}
                  setConfirmMessage={setConfirmMessage}
                />
                <TablePagination
                  className="px-4"
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component="div"
                  count={productObj?.item2}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    "aria-label": "Previous Page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Next Page",
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </div>
            </SimpleCard>
          )}
        </ValidatorForm>
      </div>
    </>
  );
};
export default ProductList;
